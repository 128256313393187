import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons
} from '@ionic/react';
import styled from 'styled-components';

import PracticeList from '../components/practice-list';
import CalendarHeader from '../components/calendar-header';
import { DriboContext } from '../contexts/driboContext';
import { PrivateRouteNavigationProps } from '../components/private-route';

const CalendarContainer = styled.div`
  .calendar-header {
    width: 100%;
    position: fixed;
    height: 9rem;
    z-index: 10;
    background-color: white;
  }

  .practices-list {
    top: 11.5rem;
  }
`;

type CalendarPageProps = PrivateRouteNavigationProps;
class CalendarPage extends React.Component<CalendarPageProps> {
  static contextType = DriboContext;
  state = {
    practices: [],
    currTime: '',
    currentDay: new Date().toISOString()
  };

  componentDidMount() {
    const { loggedUser, history } = this.props;
    if (!loggedUser) return history.push('/login');
  }

  onDayChange(newDay: string) {
    this.setState({ currentDay: newDay });
  }

  render() {
    const { selectedCar } = this.context;
    const { loggedUser, history } = this.props;
    if (typeof loggedUser !== 'object') return null;

    return (
      <CalendarContainer>
        <IonPage id="calendar">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton color="dark-text" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <CalendarHeader
              className="calendar-header"
              day={this.state.currentDay}
              onDayChange={(newDay) => this.onDayChange(newDay)}
            />
            <PracticeList
              day={this.state.currentDay}
              loggedUser={loggedUser}
              car={selectedCar}
              history={history}
              className="practices-list"
            />
          </IonContent>
        </IonPage>
      </CalendarContainer>
    );
  }
}

export default withTranslation('calendar')(CalendarPage);
