import { useRef, useEffect } from 'react';

export const toArray = <T = any>(obj: { [key: string]: T }) => {
  if (!obj) return [];
  const nobj: { [key: string]: any } = deepClone(obj);
  return Object.keys(nobj).reduce<T[]>((acc, val) => {
    acc.push(nobj[val]);
    return acc;
  }, []);
};

export const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));

export const getHash = (input: string | number, max: number) => {
  if (typeof input === 'number') return parseInt((input % max) as any);
  return (input || '').split('').reduce((a, b) => (a += b.charCodeAt(0)), 0) % max;
};

export const normalize = (input: string) =>
  input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const isEmpty = (obj: Record<string, unknown>) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const titlerize = (value: string) => {
  return value.replace(/\w\S*/g, (v) => {
    return v.charAt(0).toUpperCase() + v.substr(1).toLowerCase();
  });
};

// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const isEllipsisActive = (e: HTMLSpanElement) => {
  return e.offsetWidth < e.scrollWidth;
};

export default {
  toArray,
  deepClone,
  getHash,
  normalize,
  isEmpty,
  titlerize,
  usePrevious,
  isEllipsisActive
};
