import React from 'react';
import { mail } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { DriboStudent } from '../types/user';
import { whatsapp, phone } from '../assets/icons/index';

const ContactButtonsContainer = styled.div`
  ion-button {
    margin: 0 0.5rem;
  }
`;

interface ContactButtonsProps {
  student: DriboStudent;
  className?: string;
  sendWhatsapp?: boolean;
}
const ContactButtons: React.FC<ContactButtonsProps> = (props) => {
  const { student, sendWhatsapp } = props;
  const classes = props.className;
  const getPhone = () => {
    if (!student.phone) return '';
    const phone = student.phone.toString();
    return phone.startsWith('+') ? phone : `+34${phone}`;
  };

  return (
    <ContactButtonsContainer className={`flex ${classes}`}>
      {student.phone && (
        <IonButton className="action-button flex circle" href={`tel:${getPhone()}`}>
          <IonIcon icon={phone} class="fill-white" />
        </IonButton>
      )}
      {student.email && !sendWhatsapp ? (
        <IonButton
          className="action-button flex circle"
          href={`mailto:${student.email}`}
          target="_blank"
        >
          <IonIcon icon={mail} class="fill-white" />
        </IonButton>
      ) : null}
      {student.phone && sendWhatsapp ? (
        <IonButton
          className="action-button flex circle"
          href={`https://wa.me/${getPhone()}`}
          target="_blank"
        >
          <IonIcon icon={whatsapp} class="fill-white" />
        </IonButton>
      ) : null}
    </ContactButtonsContainer>
  );
};

export default ContactButtons;
