import React, { useState } from 'react';
import { ActionLayout } from '../components/layouts/action-layout';
import styled from 'styled-components';
import { PrivateRouteNavigationProps } from '../components/private-route';
import { IonCheckbox, IonIcon, IonRadio, IonRadioGroup } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { DriboStudent } from '../types/user';
import Avatar from '../components/avatar';
import { getStudentName } from '../services/school';
import { SearchBar } from '../components/search-bar';
import CustomAlert from '../components/custom-alert';
import { assignPracticeToStudent } from '../services/practices';
import { useHistory, useRouteMatch } from 'react-router-dom';
import notificationsService from '../services/notifications';
import { DateUtils } from '../services/date';
import { phone, identity_card, calendar, clock } from '../assets/icons/index';
const AssignPracticeContainer = styled.div`
  .search-form {
    z-index: 99;
    background: white;
    position: sticky;
    top: 0;
    padding-bottom: 2rem;
  }
  .subtitle-info {
    font-size: 14px;
    color: #707070;
    margin-right: 1rem;
    display: inline-flex;
    align-items: center;
  }

  .subtitle-info > ion-icon {
    margin-right: 0.5rem;
  }

  .subtitle {
    margin-top: 0.75rem;
  }

  .searchbar-search-icon {
    width: 1.8rem;
    left: 15px;
  }

  .searchbar-input {
    padding-inline-start: 4.2rem !important;
    font-size: 1.6rem;
  }

  .student-card {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 0.2px solid #70707030;
  }

  .student-card:last-child {
    border: none;
  }

  .student-card-content {
    padding-left: 2.4rem;
    padding-top: 0.5rem;
  }

  .student-name {
    font-size: 1.6rem;
    font-weight: 600;
  }

  .avatar {
    width: 4.8rem;
    height: 4.8rem;
  }

  .student-list {
    width: 100%;
  }

  .search-content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .checkbox {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  ion-radio {
    width: 24px;
  }

  ion-radio::part(container) {
    width: 2.3rem;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 1);
    height: 2.3rem;
  }

  ion-radio::part(mark) {
    margin-bottom: 3px;
    margin-left: 1px;
  }

  ion-radio.radio-checked {
    --color-checked: #fff;
  }

  ion-radio.radio-checked::part(container) {
    background: #8b30ff;
    border: none;
    color: #fff;
  }

  .avatar-content {
    padding: 0;
  }

  .list {
    padding-bottom: 6.4rem;
  }

  .student-details {
    margin-top: 0.5rem;
  }

  .student-details > span {
    font-size: 1.4rem;
    font-weight: 500;
    color: #707070;
    display: inline-flex;
    align-items: center;
  }

  .student-info-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .with-margin {
    margin-right: 1.5rem;
  }
`;
export const AssignPracticePage: React.FC<
  PrivateRouteNavigationProps & { students: DriboStudent[] }
> = ({ loggedUser, students }) => {
  const { t } = useTranslation('assign-practice');
  const match = useRouteMatch();
  const history = useHistory();
  const [studentSelected, setStudentSelected] = useState<DriboStudent | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const onSearchChange = async (value: string) => {
    setSearch(value);
  };

  const onStudentChecked = (student: DriboStudent) => {
    setStudentSelected(student);
  };

  const getStudentDni = (student: DriboStudent) => {
    return student.dni;
  };

  const getStudentPhone = (student: DriboStudent) => {
    return student.phone;
  };
  const renderStudentCard = (student: DriboStudent, index: number) => {
    return (
      <div key={index} className="student-card">
        <div className="avatar">
          <Avatar className="avatar-content" user={student} />
        </div>
        <div className="student-card-content">
          <h2 className="student-name dark">{getStudentName(student)}</h2>
          <div className="student-details">
            <span className="with-margin">
              <IonIcon icon={identity_card} className="student-info-icon" />
              {getStudentDni(student)}
            </span>
            <span>
              <IonIcon icon={phone} className="student-info-icon" />
              {getStudentPhone(student)}
            </span>
          </div>
        </div>
        <div className="checkbox">
          <IonRadio className={'custom-radio'} value={student} mode="ios" />
        </div>
      </div>
    );
  };

  const assignPractice = async () => {
    setModalVisible(false);
    const { practiceDate } = match.params as Record<string, string>;

    if (studentSelected && !studentSelected.car) {
      return console.error('Student does not has a car assigned'); // eslint-disable-line
    }
    history.goBack();
    const toastId = notificationsService.showLoadingToast(t('assignPracticeLoadingToast'));
    try {
      await assignPracticeToStudent(
        studentSelected!.uid,
        loggedUser.schoolUid,
        studentSelected!.car!,
        practiceDate,
        getPracticeStart()
      );
      notificationsService.updateLoadingToast(toastId, 'success', t('assignPracticeSuccessToast'));
    } catch (e) {
      console.error('Cannot assign practice to student:', e); // eslint-disable-line
      notificationsService.updateLoadingToast(toastId, 'error', t('assignPracticeErrorToast'));
    }
    return;
  };

  const displayModal = () => {
    setModalVisible(true);
  };
  const renderStudentList = () => {
    return <div className="list">{filterStudents(students).map(renderStudentCard)}</div>;
  };

  const filterStudents = (students: DriboStudent[]) => {
    if (search) {
      return students.filter((student) => {
        return getStudentName(student).toLowerCase().includes(search.toLowerCase());
      });
    }

    return students;
  };

  const getPracticeDate = () => {
    return DateUtils.fromISOToLocal((match.params as any).practiceDate);
  };

  const getPracticeStart = () => {
    const practiceTime = (match.params as Record<string, string>).practiceTime.split('-');
    return practiceTime[0];
  };

  const getPracticeEnd = () => {
    const practiceTime = (match.params as Record<string, string>).practiceTime.split('-');
    return practiceTime[1];
  };
  return (
    <AssignPracticeContainer>
      <ActionLayout
        title={t('title')}
        renderSubtitle={() => {
          return (
            <div className="subtitle">
              <span className="subtitle-info">
                <IonIcon icon={calendar} />
                {getPracticeDate()}
              </span>
              <span className="subtitle-info">
                <IonIcon icon={clock} />
                {getPracticeStart()} a {getPracticeEnd()}
              </span>
            </div>
          );
        }}
        actions={[
          {
            label: t('primaryAction'),
            type: 'primary',
            onClick: async () => {
              return displayModal();
            },
            disabled: !studentSelected
          }
        ]}
      >
        <div className="search-content">
          <div className="search-form">
            <SearchBar placeholder={t('search')} onChange={(value) => onSearchChange(value)} />
          </div>
          <IonRadioGroup
            allowEmptySelection
            onIonChange={(change) => {
              onStudentChecked(change.detail.value);
            }}
          >
            <div className="student-list">{renderStudentList()}</div>
          </IonRadioGroup>
        </div>
      </ActionLayout>
      {studentSelected ? (
        <CustomAlert
          show={modalVisible}
          onConfirm={assignPractice}
          onCancel={() => {
            setModalVisible(false);
          }}
          title={t('confirmModalTitle')}
          text={t('confirmModalText')}
          user={studentSelected!}
          confirmButtonText={t('confirmModalCTA')}
          cancelButtonText={t('confirmModalCancelCTA')}
          practice={
            {
              practice: {
                date: (match.params as any).practiceDate,
                start: getPracticeStart(),
                end: getPracticeEnd()
              }
            } as any
          }
        />
      ) : null}
    </AssignPracticeContainer>
  );
};
