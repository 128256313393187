import React, { useRef, useState } from 'react';
import { IonContent, IonSlide, IonSlides } from '@ionic/react';
import styled from 'styled-components';

const slideOpts = {
  speed: 400
};

interface TabsProps {
  initialTabIndex?: number;
  renderTabBar: (props: RenderTabBarProps) => React.ReactNode;
  tabs: (() => React.ReactNode)[];
}

export interface RenderTabBarProps {
  goToTab: (index: number) => void;
  currentTabIndex: number;
}

/**
 *
 * This component creates an abstraction so we can create a tabs pager with it rendering our
 * own tabs selector
 *
 * The slides will fill the available height, so either provide flex:1 or height: 100% to
 * the parent element
 */
export const Tabs: React.FC<TabsProps> = ({ renderTabBar, initialTabIndex = 0, tabs = [] }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(initialTabIndex);
  const tabsRef = useRef<HTMLIonSlidesElement>(null);

  const tabBarProps: RenderTabBarProps = {
    goToTab: (tabIndex: number) => {
      tabsRef.current?.slideTo(tabIndex);
    },
    currentTabIndex: currentTabIndex
  };
  return (
    <TabsContainer>
      {renderTabBar && renderTabBar(tabBarProps)}
      <IonContent>
        <IonSlides
          tabIndex={0}
          onIonSlideWillChange={async () => {
            setCurrentTabIndex((await tabsRef.current?.getActiveIndex()) || 0);
          }}
          ref={tabsRef}
          pager={false}
          options={{ ...slideOpts, initialSlide: initialTabIndex }}
        >
          {tabs.map((tab, i) => (
            <IonSlide key={i}>{tab()}</IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  height: 100%;

  ion-slides {
    height: 100%;
  }

  ion-slide {
    align-items: flex-start;
  }

  ion-slide > div {
    width: 100%;
  }
`;
