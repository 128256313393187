import React from 'react';
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';

export const LoadingScreen = () => {
  return (
    <IonGrid className="flex flex-1 justify-center">
      <IonRow className="align-items-center">
        <IonCol>
          <IonSpinner name="dots" />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
