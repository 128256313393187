import React, { useState, useEffect, useContext } from 'react';
import storage from '../services/storage';

type DriboContextShape = {
  selectedCar: string | null;
  setSelectedCar: (carId: string) => void;
};
export const DriboContext = React.createContext<DriboContextShape>({
  selectedCar: null,
  setSelectedCar: () => {
    throw new Error('Dribo context not initialized yet');
  }
});

const CAR_STORAGE_KEY = '@CAR';

type StoredCar = {
  selectedCar: string | null;
};
export const DriboProvider: React.FC = ({ children }) => {
  const [selectedCar, setSelectedCar] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      await initFromLocalStorage();
    })();
  }, []);

  const initFromLocalStorage = async () => {
    const storageData = await storage.read<StoredCar>(CAR_STORAGE_KEY);
    if (storageData && storageData.selectedCar) {
      setSelectedCar(storageData.selectedCar);
    }
  };
  const saveSelectedCar = async (car: string) => {
    setSelectedCar(car);

    await storage.write<StoredCar>(CAR_STORAGE_KEY, { selectedCar: car });
  };

  return (
    <DriboContext.Provider value={{ selectedCar, setSelectedCar: saveSelectedCar }}>
      {children}
    </DriboContext.Provider>
  );
};

export const useDribo = () => {
  return useContext(DriboContext);
};
