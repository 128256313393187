import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PrivateRouteNavigationProps } from '../components/private-route';

import notificationsService from '../services/notifications';
import firebaseService from '../services/firebase-adapter';
import practiceService from '../services/practices';
import { PerformTeacherActionResponse } from '../types/functions';

import { ActionLayout } from '../components/layouts/action-layout';
import { IonRadioGroup, IonItem, IonLabel, IonRadio, IonIcon } from '@ionic/react';
import { event_gray, clock_gray } from '../assets/icons/index';

type LockPracticePageProps = PrivateRouteNavigationProps;
const LockPracticePage: React.FC<LockPracticePageProps> = (props) => {
  const { history } = props;
  const { t } = useTranslation('lock-practice');
  const [lockReason, setLockReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');

  const onOptionSelected = (lockReason: string) => {
    setLockReason(lockReason);
  };

  const lockSlot = () => {
    history.goBack();
    const toastId = notificationsService.showLoadingToast(t('loadingText'));
    const year = props.match.params.year;
    const month = props.match.params.month;
    const day = props.match.params.day;
    const practiceDate = [year, month, day].join('-');
    const practiceStart = props.match.params.slot.split('-').shift() || '';
    const carId = props.match.params.car;
    const schoolId = props.loggedUser.schoolUid;
    const reason = lockReason === t('other') && otherReason !== '' ? otherReason : lockReason;
    return practiceService
      .lockTeacherSlot(practiceDate, practiceStart, schoolId, carId, reason)
      .then(() => {
        notificationsService.updateLoadingToast(toastId, 'success', t('successText'));
      })
      .catch(() => {
        notificationsService.updateLoadingToast(toastId, 'error', t('errorText'));
      });
  };

  return (
    <LockPracticePageContainer>
      <ActionLayout
        title={t('title')}
        renderSubtitle={() => {
          const year = props.match.params.year;
          const month = props.match.params.month;
          const day = props.match.params.day;
          const date = [year, month, day].join('/');
          const start = props.match.params.slot.split('-').shift();
          const end = props.match.params.slot.split('-').pop();
          return (
            <div className="flex date-info">
              <div className="flex">
                <IonIcon icon={event_gray} />
                <span className="main-text-small date">{date}</span>
              </div>
              <div className="flex">
                <IonIcon icon={clock_gray} />
                <span className="main-text-small">
                  {start} {t('to')} {end}
                </span>
              </div>
            </div>
          );
        }}
        separator={true}
        actions={[
          {
            label: t('buttonText'),
            onClick: () => {
              return lockSlot();
            },
            type: 'primary',
            disabled: lockReason === '' || (lockReason === t('other') && otherReason === '')
          }
        ]}
      >
        <IonRadioGroup value={lockReason} onIonChange={(e) => onOptionSelected(e.detail.value)}>
          <IonItem lines="none" className="item-subtitle">
            <IonLabel className="main-text-dark">{t('exam')}</IonLabel>
            <IonRadio slot="start" value={t('exam')} mode="ios" />
          </IonItem>

          <IonItem lines="none" className="item-subtitle">
            <IonLabel className="main-text-dark">{t('holidays')}</IonLabel>
            <IonRadio slot="start" value={t('holidays')} mode="ios" />
          </IonItem>

          <IonItem lines="none" className="item-subtitle">
            <IonLabel className="main-text-dark">{t('illness')}</IonLabel>
            <IonRadio slot="start" value={t('illness')} mode="ios" />
          </IonItem>

          <IonItem lines="none" className="item-subtitle">
            <IonLabel className="main-text-dark">{t('carRepair')}</IonLabel>
            <IonRadio slot="start" value={t('carRepair')} mode="ios" />
          </IonItem>

          <div className="flex">
            <IonItem lines="none" className="item-subtitle">
              <IonLabel>{t('other')}:</IonLabel>
              <IonRadio slot="start" value={t('other')} mode="ios" />
            </IonItem>
            <input
              className="item-subtitle margin-vertical-auto"
              value={otherReason}
              disabled={lockReason !== t('other')}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          </div>
        </IonRadioGroup>
      </ActionLayout>
    </LockPracticePageContainer>
  );
};

export default LockPracticePage;

const LockPracticePageContainer = styled.div`
  .date-info {
    margin: 1rem 0 2.5rem 0;
  }

  span {
    margin-left: 0.6rem;
    padding-top: 0.05rem;
  }

  .date {
    margin-right: 0.9rem;
  }

  ion-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  ion-label {
    --color: var(--ion-color-dark-text) !important;
    margin: 0 !important;
  }

  ion-radio-group ion-item::part(native) {
    padding-inline-start: 0;
  }

  ion-radio {
    width: 1.9rem;
    height: 1.9rem;
    margin-right: 1.6rem;
  }

  ion-radio::part(container) {
    width: 1.8rem;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 1);
    height: 1.8rem;
  }

  ion-radio::part(mark) {
    margin-bottom: 3px;
    border-width: 2px;
    border-top-width: 0;
    border-left-width: 0;
    width: 33%;
    height: 63%;
  }

  ion-radio.radio-checked {
    --color-checked: #fff;
  }

  ion-radio.radio-checked::part(container) {
    background: #8b30ff;
    border: none;
    color: #fff;
  }

  input {
    border: none;
    border-bottom: 1px solid var(--ion-color-light-text);
    height: 3rem;
    color: var(--ion-color-dark-text);
  }

  input:focus {
    border-bottom: 1px solid var(--ion-color-primary);
    outline: none;
  }
`;
