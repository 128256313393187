import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import config from '../config';
import { DriboTeacher } from '../types/user';

let app: firebase.app.App | null = null;
if (!firebase.apps.length) {
  app = firebase.initializeApp(config.firebase);
} else {
  app = firebase.app();
}
const bucket$ = app.storage();
const auth$ = app.auth();
const datab$ = app.database();
const functions$ = app.functions();

const toJSON = <T>(snapshot: any) => snapshot.toJSON() as T;

export const callFunction = <T>(name: string, params?: any) => {
  return functions$
    .httpsCallable(name)(params)
    .then((r) => {
      return r.data as T;
    });
};

export const authFirebaseUser = (email: string, pass: string) => {
  return auth$.signInWithEmailAndPassword(email, pass).then((authRef) => {
    return authRef.user;
  });
};

export const signOutFirebaseUser = () => {
  return auth$.signOut();
};

export type FirebaseListenerCallback = (
  a: firebase.database.DataSnapshot,
  b?: string | null
) => any;
export const listen = (path: string, cbk: FirebaseListenerCallback) => {
  const ref = datab$.ref(path);
  ref.on('value', cbk);
  return () => {
    ref.off('value');
  };
};

export const createSession = (user: DriboTeacher) => {
  const payload = { email: user.email, issuedAt: Date.now() };
  return databasePush(`sessions/${user.uid}`, payload).then((session) => {
    return {
      ...user,
      ...(session as any)
    };
  });
};

export const databasePush = (path: string, payload: any) => {
  return datab$.ref(path).push(payload).once('value').then(toJSON);
};

export const databaseGet = <T>(path: string) =>
  datab$
    .ref(path)
    .once('value')
    .then((v) => toJSON<T>(v));
export const databaseQueryByChild = <T>(
  path: string,
  child: string,
  value: string | number | boolean | null
) => {
  return datab$
    .ref(path)
    .orderByChild(child)
    .equalTo(value)
    .once('value')
    .then((v) => toJSON<T>(v));
};

export const databaseChange = <T>(path: string, cbk: (result: T | null) => void) =>
  datab$.ref(path).on('value', (res) => cbk(res ? (res.toJSON() as T) : null));

export const databaseSet = (path: string, value: any) => datab$.ref(path).set(value);

export const update = (path: string, object: any) => datab$.ref(path).update(object);

export const storeBase64 = (path: string, id: string, base64: string) =>
  bucket$.ref(path).child(id).putString(base64, 'data_url');

export const getDownloadURL = (snap: firebase.storage.UploadTaskSnapshot) =>
  snap.ref.getDownloadURL();

export const getSignedUrl = async (path: string) => {
  try {
    if (path.includes('http')) {
      return path;
    } else {
      return await bucket$.ref(path).getDownloadURL();
    }
  } catch (error) {
    // If we don't have permission to access the file Firebase will throw
    // an error, since this operation should be as simple as possible, we just
    // return undefined and log the error.

    console.error('Cannot generate a signed URL for the file', error); // eslint-disable-line

    return undefined;
  }
};

export const AuthListener = (callback: (user: firebase.User | null) => void) => {
  auth$.onAuthStateChanged((user) => callback(user));
};

export const sendPasswordResetEmail = (email: string) => {
  return auth$.sendPasswordResetEmail(email);
};

export default {
  databasePush,
  getDownloadURL,
  storeBase64,
  databaseSet,
  callFunction,
  databaseGet,
  update,
  databaseChange,
  databaseQueryByChild,
  authFirebaseUser,
  signOutFirebaseUser,
  listen,
  createSession,
  sendPasswordResetEmail
};
