import { IonIcon } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Translated } from '../../types/route';
import LoadingButton from '../loading-button';
import { checkmarkCircle } from 'ionicons/icons';
export const PasswordRecoverSuccess: React.FC<
  Translated & { onRecoveryPasswordFinished: () => void }
> = ({ t, onRecoveryPasswordFinished }) => {
  return (
    <StyleContainer>
      <div className="success-message">
        <IonIcon icon={checkmarkCircle} className="check-icon" />
        <h2 className="center title-1 heading success-message-header">
          {t('password_recover_success_header')}
        </h2>
        <h3 className="center main-text">{t('password_recover_success_message')}</h3>
      </div>
      <div>
        <LoadingButton onClick={async () => onRecoveryPasswordFinished()} expand="block">
          {t('password_recover_success_cta')}
        </LoadingButton>
      </div>
    </StyleContainer>
  );
};

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .check-icon {
    font-size: 40px;
    fill: #42f4c4;
  }

  .success-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding-bottom: 2rem;
  }
  .success-message-header {
    margin-top: 1rem;
  }
`;
