import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDribo } from '../contexts/driboContext';
import { PrivateRouteNavigationProps } from '../components/private-route';

import practiceService from '../services/practices';
import userService from '../services/user';
import { DateUtils } from '../services/date';
import firebaseService from '../services/firebase-adapter';
import notificationsService from '../services/notifications';
import { DriboPracticeDetail } from '../types/practice';
import { DriboStudent } from '../types/user';

import { ActionLayout } from '../components/layouts/action-layout';
import PracticeSummaryCard from '../components/practice-summary-card';
import CustomAlert from '../components/custom-alert';
import {
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonSpinner,
  IonPage,
  IonIcon
} from '@ionic/react';

type CancelPracticePageProps = PrivateRouteNavigationProps;
const CancelPracticePage: React.FC<CancelPracticePageProps> = (props) => {
  const { loggedUser, history } = props;
  const { selectedCar } = useDribo();
  const { t } = useTranslation('cancel-practice');
  const [cancelReason, setCancelReason] = useState<string | null>(null);
  const [practice, setPractice] = useState<DriboPracticeDetail>();
  const [student, setStudent] = useState<DriboStudent>();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getPractice();
  }, []);

  const getPractice = () => {
    practiceService
      .fetchDetail(loggedUser.schoolUid, (props.match.params as any).practiceId.split(',')[0])
      .then((practice) => {
        setPractice(practice);
        getUser(practice.user);
      });
  };

  const getUser = (userId: string) => {
    return userService.fetchStudentProfile(userId).then((student) => setStudent(student));
  };

  const getCompleteName = (user: DriboStudent | undefined) => {
    return user ? userService.fullName(user) : '';
  };

  const onOptionSelected = (cancelReason: string) => {
    setCancelReason(cancelReason);
  };

  const cancelPractice = (practice: DriboPracticeDetail) => {
    if (!cancelReason || !selectedCar) return;
    setShowAlert(false);
    history.goBack();
    const toastId = notificationsService.showLoadingToast(t('loadingToast'));
    const practices = props.match.params.practiceId.split(',');
    const schoolId = props.loggedUser.schoolUid;
    return Promise.all(
      practices.map((practiceId) => {
        return practiceService.cancelUserPractice(
          schoolId,
          selectedCar,
          practice.user,
          practiceId,
          cancelReason
        );
      })
    )
      .then((res) => {
        notificationsService.updateLoadingToast(toastId, 'success', t('successToast'));
      })
      .catch((e) => {
        notificationsService.updateLoadingToast(toastId, 'error', t('errorToast'));
      });
  };

  return (
    <CancelPracticeContainer>
      <ActionLayout
        title={t('title')}
        renderSubtitle={() => {
          if (!student || !practice) return <></>;
          return (
            <div className="summary-card">
              <PracticeSummaryCard
                user={student}
                date={DateUtils.fromISOToLocal(practice.practice.date)}
                start={practice.practice.start}
                end={practice.practice.end}
                mode="row"
              />
            </div>
          );
        }}
        separator={!student || !practice ? false : true}
        actions={[
          {
            label: t('buttonText'),
            onClick: () => {
              setShowAlert(true);
              return Promise.resolve();
            },
            type: 'primary',
            disabled: !cancelReason
          }
        ]}
      >
        <div className="width-100">
          {!student || !practice || typeof loggedUser !== 'object' ? (
            <IonSpinner name="dots" />
          ) : (
            <div>
              <p className="semibold main-text-large reason">{t('radioGroupTitle')}</p>
              <IonRadioGroup
                value={cancelReason}
                onIonChange={(e) => onOptionSelected(e.detail.value)}
              >
                <IonItem lines="none" className="item-subtitle">
                  <IonLabel className="main-text-dark">{t('exam')}</IonLabel>
                  <IonRadio slot="start" value={t('exam')} mode="ios" />
                </IonItem>

                <IonItem lines="none" className="item-subtitle">
                  <IonLabel className="main-text-dark">{t('holidays')}</IonLabel>
                  <IonRadio slot="start" value={t('holidays')} mode="ios" />
                </IonItem>

                <IonItem lines="none" className="item-subtitle">
                  <IonLabel className="main-text-dark">{t('illness')}</IonLabel>
                  <IonRadio slot="start" value={t('illness')} mode="ios" />
                </IonItem>

                <IonItem lines="none" className="item-subtitle">
                  <IonLabel className="main-text-dark">{t('carRepair')}</IonLabel>
                  <IonRadio slot="start" value={t('carRepair')} mode="ios" />
                </IonItem>
              </IonRadioGroup>
              <CustomAlert
                show={showAlert}
                user={student}
                practice={practice}
                onConfirm={() => cancelPractice(practice)}
                onCancel={() => setShowAlert(false)}
                title={t('alertTitle')}
                text={t('alertText')}
                confirmButtonText={t('confirmAlert')}
                cancelButtonText={t('cancelAlert')}
              />
            </div>
          )}
        </div>
      </ActionLayout>
    </CancelPracticeContainer>
  );
};

export default CancelPracticePage;

const CancelPracticeContainer = styled.div`
  .summary-card {
    margin: 2.5rem 0;
  }

  ion-label {
    --color: var(--ion-color-dark-text) !important;
    margin: 0 !important;
  }

  ion-radio-group ion-item::part(native) {
    padding-inline-start: 0;
  }

  ion-radio {
    width: 1.9rem;
    height: 1.9rem;
    margin-right: 1.6rem;
  }

  ion-radio::part(container) {
    width: 1.8rem;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 1);
    height: 1.8rem;
  }

  ion-radio::part(mark) {
    margin-bottom: 3px;
    border-width: 2px;
    border-top-width: 0;
    border-left-width: 0;
    width: 33%;
    height: 63%;
  }

  ion-radio.radio-checked {
    --color-checked: #fff;
  }

  ion-radio.radio-checked::part(container) {
    background: #8b30ff;
    border: none;
    color: #fff;
  }

  p.reason {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
