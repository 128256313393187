import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LoadingButton from '../loading-button';
import Input from '../input';
import { recoverPassword } from 'src/services/user';
import { PasswordRecoverSuccess } from './password-recover-success';
import { useLocation } from 'react-router-dom';
export const PasswordRecover: React.FC<{ backToSignIn: () => void }> = ({ backToSignIn }) => {
  const { t } = useTranslation('password-recover');
  const location = useLocation();
  const [email, setEmail] = useState<string>();
  const [status, setStatus] = useState<'hasError' | 'success' | undefined>();
  const handleEmailSubmit = async () => {
    try {
      await recoverPassword(email!);
      setStatus('success');
    } catch (e) {
      setStatus('hasError');
    }
  };

  const isDisabled = () => {
    if (!email) return true;
    return email.length === 0;
  };

  const renderError = () => {
    if (status === 'hasError') {
      return <p className="error-msg">{t('submit_error')}</p>;
    } else {
      return <></>;
    }
  };

  const onRecoveryPasswordFinished = async () => {
    backToSignIn();
    setStatus(undefined);
  };
  const renderByStatus = () => {
    if (!status || status === 'hasError') {
      return (
        <>
          <div className="welcome">
            <h1 className="center title-1 semibold heading">{t('password_recover_header')}</h1>
            <h2 className="center main-text">{t('password_recover_hint')}</h2>
          </div>

          <div className="email-input-wrapper">
            <Input value={email} onChange={setEmail} label={t('email_label')} />
          </div>
          <div className="error-wrapper">{renderError()}</div>
          <div className="submit-button-wrapper">
            <LoadingButton onClick={handleEmailSubmit} expand="block" disabled={isDisabled()}>
              {t('password_recover_cta')}
            </LoadingButton>
          </div>
        </>
      );
    } else if (status === 'success') {
      return (
        <PasswordRecoverSuccess t={t} onRecoveryPasswordFinished={onRecoveryPasswordFinished} />
      );
    }
  };

  return (
    <StyleContainer>
      <div className="password-recover-container round-top">{renderByStatus()}</div>
    </StyleContainer>
  );
};

const StyleContainer = styled.div`
  .password-recover-container {
    bottom: 0;
    position: absolute;
    width: 100%;
    padding: 2rem;
    padding-top: 4rem;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .heading {
    margin-bottom: 0.75rem;
  }

  .email-input-wrapper {
    margin-top: 1rem;
  }
  .error-wrapper {
    height: 4rem;
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
  }
  .error-msg {
    font-size: 13px;
    text-align: center;
    display: block;
    margin: 0;
    color: var(--ion-color-red);
  }
`;
