import React from 'react';
import { withTranslation } from 'react-i18next';
//@ts-ignore
import { stylesheet } from 'stylesheet-decorator/react';
import practiceService, { unassignPractice } from '../services/practices';
import { isMulticar } from '../services/school';
import notificationsService from '../services/notifications';
import { DateUtils } from '../services/date';
import { IonSpinner, IonActionSheet } from '@ionic/react';

import PracticeSlot from './practice-slot';
import { PrivateRouteNavigationProps } from './private-route';
import { BookedSlotWithRange } from '../types/practice';
import { Translated } from '../types/route';

import {
  forbidden,
  lock as lockIcon,
  unlock,
  person_add,
  person_cancelled
} from '../assets/icons/index';
import CustomAlert from './custom-alert';

const cellHeight = 96; //px

interface PracticeListProps extends PrivateRouteNavigationProps, Translated {
  className?: string;
  day: string;
  car: string;
}

interface PracticeListState {
  practices: BookedSlotWithRange[];
  loading: boolean;
  currTime: string;
  slotSelected: boolean;
  selectedSlot: BookedSlotWithRange | null;
  selectedOption?: 'cancel' | 'assign' | 'lock';
  assignedPracticeToCancel?: BookedSlotWithRange;
  multicar: boolean;
}
class PracticeList extends React.Component<PracticeListProps, PracticeListState> {
  private interval: NodeJS.Timeout | null = null;
  private detach: ((a?: any | null, b?: string | undefined) => any) | null = null;
  state: PracticeListState = {
    practices: [],
    currTime: this.getHoursAndMin(),
    loading: true,
    slotSelected: false,
    selectedSlot: null,
    multicar: false
  };

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ currTime: this.getHoursAndMin() }), 60000);
    this.loadPractices();
  }

  componentWillUnmount() {
    typeof this.detach === 'function' && this.detach();
    this.interval && clearInterval(this.interval);
  }

  getHoursAndMin() {
    return DateUtils.getCurrentTimeISO();
  }

  componentDidUpdate(prevProps: PracticeListProps) {
    if (prevProps.day === this.props.day && prevProps.car === this.props.car) return;

    typeof this.detach === 'function' && this.detach();
    this.interval && clearInterval(this.interval);
    this.loadPractices();
  }

  async loadPractices() {
    const { loggedUser, day, car } = this.props;
    this.setState({ loading: true });
    const multicar = await isMulticar(loggedUser.schoolUid, car);
    this.setState({ multicar });
    this.detach = practiceService.listenTeacherPractices(loggedUser, car, day, () => {
      practiceService.fetchTeacherBookedPractices(loggedUser, car, day).then((practices) => {
        const filteredPractices = this.filterMultiplePractices(practices);
        this.setState({ practices: filteredPractices, loading: false });
      });
    });
  }

  filterMultiplePractices(practices: BookedSlotWithRange[]) {
    const includedIds = practices
      .filter((practice) => practice.multiple)
      .reduce((acc: string[], val) => {
        if (val.includedPractices) {
          Object.values(val.includedPractices).forEach((id) => acc.push(id));
        }
        return acc;
      }, []);

    return practices.reduce((filteredPractices: BookedSlotWithRange[], practice) => {
      if (practice.user) {
        if (practice.user.role === 'school') filteredPractices.push(practice);
        if (practice.multiple) {
          practice.end = practice.lastPracticeEnd ? practice.lastPracticeEnd : practice.end;
          filteredPractices.push(practice);
        } else if (practice.practiceId && !includedIds.includes(practice.practiceId))
          filteredPractices.push(practice);
      } else filteredPractices.push(practice);
      return filteredPractices;
    }, []);
  }

  onSlotClicked(practice: BookedSlotWithRange) {
    if (practice.includedPractices) {
      const includedIds = Object.values(practice.includedPractices).join(',');
      return this.props.history.push(`/practice-detail/${includedIds}`);
    }
    this.props.history.push(`/practice-detail/${practice.practiceId}`);
  }

  getTeacherPermissions() {
    const { cancel, assign, lock } = this.props.loggedUser;
    return { cancel, assign, lock };
  }

  isLocked(practice: BookedSlotWithRange) {
    return practice.user && practice.user.role === 'school' && practice.status !== 'pre-booked';
  }

  isPrebooked(practice: BookedSlotWithRange) {
    return practice.status && practice.status === 'pre-booked';
  }

  isAssigned(practice: BookedSlotWithRange) {
    return practice.status === 'pre-booked' && practice.expires;
  }

  isBooked(practice: BookedSlotWithRange) {
    return practice.user && practice.user.role !== 'school' && practice.status !== 'pre-booked';
  }

  onDotsClicked(practice: BookedSlotWithRange) {
    this.setState({ selectedSlot: practice, slotSelected: true });
  }

  cancelPractice(practice: BookedSlotWithRange | null) {
    if (!practice) return;
    if (practice.includedPractices) {
      const includedIds = Object.values(practice.includedPractices).join(',');
      return this.props.history.push(`/cancel-practice/${includedIds}`);
    }
    this.props.history.push(`/cancel-practice/${practice.practiceId}`);
  }

  lockPractice(practice: BookedSlotWithRange | null) {
    if (!practice) return;
    const { car } = this.props;
    const localDay = DateUtils.fromISOToLocal(this.props.day.split('T')[0]);
    const localDayReverse = localDay.split('/').reverse().join('/');
    this.props.history.push(
      `/lock-practice/${car}/${localDayReverse}/${practice.start}-${practice.end}`
    );
  }

  unlockPractice(practice: BookedSlotWithRange | null) {
    if (!practice) return;
    const { car, day, t, loggedUser } = this.props;
    const date = day.split('T').shift() || '';
    const start = practice.start;
    this.setState({ selectedSlot: null, slotSelected: false });
    const toastId = notificationsService.showLoadingToast(t('unlockingText'));
    return practiceService
      .unlockTeacherSlot(date, start, loggedUser.schoolUid, car)
      .then(() => {
        notificationsService.updateLoadingToast(toastId, 'success', t('successUnlockText'));
      })
      .catch(() => {
        notificationsService.updateLoadingToast(toastId, 'error', t('errorUnlockText'));
      });
  }

  assignPractice(practice: BookedSlotWithRange | null) {
    if (!practice) return;
    this.props.history.push(
      `/assign-practice/${this.props.day.split('T')[0]}/${practice.start}-${practice.end}`
    );
  }

  cancelAssignedPractice(practice?: BookedSlotWithRange) {
    const { t, loggedUser, car } = this.props;
    const toastId = notificationsService.showLoadingToast(t('unlockingText'));
    if (!practice) return;
    return unassignPractice(loggedUser.schoolUid, car, practice.user!.uid, practice.practiceId!)
      .then(() => {
        notificationsService.updateLoadingToast(toastId, 'success', t('successUnassignPractice'));
      })
      .catch(() => {
        notificationsService.updateLoadingToast(toastId, 'error', t('failedUnassignPractice'));
      });
  }

  setActionButtons() {
    const { selectedSlot } = this.state;
    const { t } = this.props;
    const { lock, assign, cancel } = this.getTeacherPermissions();
    if (!selectedSlot) return [];
    const actions = [];
    if (cancel && this.isBooked(selectedSlot)) {
      actions.push({
        text: t('cancelPractice'),
        icon: forbidden,
        handler: () => this.cancelPractice(this.state.selectedSlot)
      });
    }
    if (lock && this.isLocked(selectedSlot) && !this.isAssigned(selectedSlot)) {
      actions.push({
        text: t('unlockPractice'),
        icon: unlock,
        handler: () => this.unlockPractice(this.state.selectedSlot)
      });
    }
    if (
      lock &&
      !this.isBooked(selectedSlot) &&
      !this.isLocked(selectedSlot) &&
      !this.isPrebooked(selectedSlot)
    ) {
      actions.push({
        text: t('lockPractice'),
        icon: lockIcon,
        handler: () => this.lockPractice(this.state.selectedSlot)
      });
    }

    if (assign && this.isAssigned(selectedSlot)) {
      actions.push({
        text: t('cancelAssignedPractice'),
        icon: person_cancelled,
        handler: () => this.setState({ assignedPracticeToCancel: selectedSlot })
      });
    }
    if (
      assign &&
      !this.isBooked(selectedSlot) &&
      !this.isLocked(selectedSlot) &&
      !this.isAssigned(selectedSlot)
    ) {
      actions.push({
        text: t('assignPractice'),
        icon: person_add,
        handler: () => this.assignPractice(this.state.selectedSlot)
      });
    }

    return actions;
  }

  render() {
    const { t } = this.props;
    const { practices, currTime, slotSelected, selectedSlot } = this.state;
    const currMinutes = DateUtils.getMinutes(currTime);
    const currHour = DateUtils.getHour(currTime);
    const isToday = DateUtils.isToday(this.props.day);
    const classes = this.props.className;
    const hourIndicatorPos = (cellHeight * currMinutes) / 60 + (currHour - 6) * cellHeight;
    const customActionSheetOptions = {
      cssClass: 'car-options'
    };

    return (
      <div className={`${classes} flex calendar relative`}>
        <div>
          <div>
            <div className="clock relative">
              {isToday && (
                <div
                  className="current-hour absolute"
                  style={{ top: hourIndicatorPos - 10 + 'px' }}
                >
                  <span>{currTime}</span>
                </div>
              )}
              {[...Array(18).keys()].map((hour) => (
                <div key={hour} className="hour-cell">
                  <span className="hour light">
                    {(hour + 6).toString().padStart(2, '0') + ':00'}
                  </span>
                  <div className="quarter-divisions">
                    <hr />
                    <hr className="half-hour" />
                    <hr />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="practice-list">
          <div>
            {[...Array(18).keys()].map((hour, i) => (
              <div key={i} className="border-top" />
            ))}
          </div>
          <div className="border-right" />
          {this.state.loading && <IonSpinner name="dots" />}
          {!this.state.loading && (
            <div className="relative width-100 height-100">
              {isToday && (
                <hr className="current-hour-line" style={{ top: hourIndicatorPos - 1 + 'px' }} />
              )}
              {isToday && (
                <div className="current-hour-dot" style={{ top: hourIndicatorPos - 1 + 'px' }} />
              )}
              <div className="relative height-100 width-100">
                {practices.map((practice, i) => (
                  <PracticeSlot
                    key={i}
                    practice={practice}
                    cellHeight={cellHeight}
                    onSlotClicked={() => practice.practiceId && this.onSlotClicked(practice)}
                    currentDay={this.props.day}
                    teacherPermissions={this.getTeacherPermissions()}
                    onDotsClicked={() => this.onDotsClicked(practice)}
                    isMulticar={this.state.multicar}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <>
          {this.state.assignedPracticeToCancel ? (
            <CustomAlert
              show={this.state.assignedPracticeToCancel ? true : false}
              onConfirm={async () => {
                this.setState({ assignedPracticeToCancel: undefined });
                await this.cancelAssignedPractice(this.state.assignedPracticeToCancel);
              }}
              onCancel={() => {
                this.setState({ assignedPracticeToCancel: undefined });
              }}
              title={t('cancelAssignedPracticeModalTitle')}
              text={t('cancelAssignedPracticeModalText')}
              user={this.state.assignedPracticeToCancel.user as any}
              confirmButtonText={t('cancelAssignedPracticeModalCTA')}
              cancelButtonText={t('cancelAssignedPracticeModalCancel')}
              practice={
                {
                  practice: {
                    date: this.props.day.split('T').shift() || '',
                    start: this.state.assignedPracticeToCancel.start,
                    end: this.state.assignedPracticeToCancel.end
                  }
                } as any
              }
            />
          ) : null}
          {slotSelected ? (
            <IonActionSheet
              isOpen={slotSelected}
              onDidDismiss={() => this.setState({ slotSelected: false, selectedSlot: null })}
              cssClass="teacher-action-sheet"
              mode="md"
              buttons={this.setActionButtons()}
            ></IonActionSheet>
          ) : null}
        </>
      </div>
    );
  }
}

export default stylesheet<any>(
  `
  ion-spinner {
    top: 12%;
  }
  .border-right {
    width: 8px;
    border-right: #dadce0 1px solid;
    height: 100%;
  }

  .border-top {
    height: ${cellHeight / 10}rem;
  }

  .border-top::after {
    content: '';
    border-bottom: #dadce0 1px solid;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    z-index: 3;
    pointer-events: none;
  }

  .current-hour {
    background-color: #8E1DFF;
    border-radius: 0.3rem;
    padding: 0.3rem;
    z-index: 1;
  }

  .current-hour span {
    color: white;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .current-hour-line {
    position: absolute;
    z-index: 9;
    background-color: #8E1DFF;
    left: 0;
    right: 0;
    pointer-events: none;
    height: 1px;
    width: 100%;
  }

  .current-hour-dot {
    background: #8E1DFF;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    margin-left: -3.5px;
    margin-top: -3px;
    z-index: 9;
  }

  .calendar {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    flex: 1 1 auto;
  }

  .practice-list {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex: 1 1 auto;
  }

  .hour-cell {
    position: relative;
    height: ${cellHeight / 10}rem;
    padding-right: 1rem;
    text-align: right;
  }

  .hour {
    display: block;
    position: relative;
    top: -0.7rem;
  }

  .quarter-divisions {
    margin-top: 0.7rem;
    margin-bottom: 1.5rem;
    width: max-content;
  }

  hr {
    background-color: rgba(112, 112, 112, 0.3);
    margin: 0;
    width: 0.75rem;
  }

  hr.half-hour {
    margin-top: 1.9rem;
    margin-bottom: 2rem;
    width: 1.5rem;
  }

  .teacher-action-sheet {
    padding: 1rem 0;
  }

`,
  withTranslation('calendar')(PracticeList)
);
