const loadConfigFile = (env: string) => {
  const requireModule = (path: string) => {
    try {
      // Hack to avoid "Critical dependency error in webpack"
      // eslint-disable-next-line
      const module = require(`${path}`);
      // eslint-disable-next-line
      return module && module.default;
    } catch (e) {
      // Silence is gold, unless we are in production
      if (env === 'production') {
        throw e;
      }
    }
  };
  switch (env) {
    case 'pipeline':
      return JSON.parse(process.env.CI_CONFIG || '');
    case 'development':
      return requireModule('./environments/development');
    case 'production':
      return requireModule('./environments/production');
  }
};

/**
 * USAGE
 *
 * 1 - Create your environment in environments directory
 * 2 - Export a default const with the environment
 * 3 - Register a require statement in loadConfigFile
 * Example:
 *
 * ```require('./environments/development').default```
 *
 *
 * 4 - Call the script with NODE_ENV=name_of_the_environment the name of the environment should match the
 * name of the constant you are exporting
 * */
export const getCurrentEnv = () => {
  const getEnvKey = () => {
    if (process.env.CI === 'true') {
      return 'pipeline';
    } else {
      return process.env.CONFIG_FILE;
    }
  };
  const envKey = getEnvKey();
  const currentEnv = loadConfigFile(envKey);
  if (!currentEnv) {
    throw new Error(`'${envKey}' config not found`);
  }
  return currentEnv;
};
export default getCurrentEnv();
