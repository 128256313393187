import { IonItem } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateUtils } from 'src/services/date';
import { DetailedPractice } from 'src/types/practice';
import styled from 'styled-components';
import Avatar from './avatar';
import Utils from '../utils';

const COLOR_COUNT = 8;

interface StudentPracticesListProps {
  studentPractices: any[];
  // Reverse the practices list
  reverse?: boolean;

  // If we should navigate when user press the card
  shouldNavigateToDetail?: boolean;
}
export const StudentPracticesList: React.FC<StudentPracticesListProps> = ({
  studentPractices,
  reverse,
  shouldNavigateToDetail
}) => {
  const { t } = useTranslation('student-practices-list');
  const { push } = useHistory();
  const isNotAppeared = (practice: DetailedPractice) => {
    return practice.details && practice.details.notAppeared;
  };

  const isMultiple = (practice: DetailedPractice) => practice.position;

  const goToDetail = (practiceId: string, index: number) =>
    push(`/practice-detail/${practiceId}#${index}`);

  const renderPractices = () => {
    return studentPractices.map((sp, i: number) => {
      // Is an exam practice, don't render it
      if (!sp.practice.start && !sp.practice.end) {
        return <></>;
      }
      const practiceColor = sp.practice.includedPractices
        ? Utils.getHash(sp.practice.includedPractices[0], COLOR_COUNT)
        : i;
      return (
        <IonItem
          onClick={() => (shouldNavigateToDetail ? goToDetail(sp.pid, i + 1) : null)}
          lines="none"
          className="card"
          key={`practice-${i}`}
        >
          <div className="relative">
            {isNotAppeared(sp.practice) ? (
              <div className="not-appeared-chip round absolute">{t('notAppeared')}</div>
            ) : null}
            <Avatar index={practiceColor} text={`#${i + 1}`} />
          </div>
          <div className="flex-vertical">
            <span className="main-text-small">{`${sp.practice.start} ${t('to')} ${
              sp.practice.end
            }`}</span>
            <span className="medium dark">{DateUtils.toLocale(sp.practice.date)}</span>
            {isMultiple(sp.practice) ? (
              <div className={`chip small absolute chip-color-${practiceColor + 1}`}>
                {sp.practice.position}
              </div>
            ) : null}
          </div>
        </IonItem>
      );
    });
  };
  return (
    <StudentPracticesListContainer>
      {reverse ? renderPractices().reverse() : renderPractices()}
    </StudentPracticesListContainer>
  );
};

const StudentPracticesListContainer = styled.div`
  ion-item.card {
    margin: 1rem 2rem;
    --padding-start: 0;
    --padding-end: 0;
    min-height: auto;
    height: auto;
  }

  ion-avatar.avatar {
    margin-right: 1.4rem !important;
    width: 4.7rem;
    height: 4.7rem;
  }

  .not-appeared-chip {
    background: var(--ion-color-dark-text);
    border-radius: 50px;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 9px;
    padding-bottom: 1px;
    padding-left: 1px;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    z-index: 1;
    right: 0.3rem;
    top: -0.3rem;
    border: 3px solid white;
    position: absolute;
  }

  .chip {
    top: 50%;
    transform: translateY(-50%);
    right: 0.9rem;
  }
`;
