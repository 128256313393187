const sample: AppConfig = {
  firebase: {
    apiKey: 'apiKey',
    authDomain: 'authDomain',
    databaseURL: 'databaseURL',
    projectId: 'projectId',
    storageBucket: 'storageBucket',
    messagingSenderId: 'messagingSenderId'
  },
  credentials: {
    email: 'test@example.com',
    password: 'password'
  },
  i18n: {
    debug: true
  },
  functions: 'functions',
  intercom: {
    appId: 'intercomAppId'
  }
};

export default sample;
export interface AppConfig {
  firebase: FirebaseConfig;
  credentials?: DevCredentials;
  functions: string;
  i18n?: i18nConfig;
  intercom: IntercomConfig;
}

type IntercomConfig = {
  appId: string;
};

type i18nConfig = {
  debug?: boolean;
};

type DevCredentials = {
  email: string;
  password: string;
};

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}
