import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonModal } from '@ionic/react';
import { car, close } from 'ionicons/icons';

import UserService from '../services/user';
import Utils from '../utils';

import Input from './input';
import LoadingButton from './loading-button';
import SignaturePad from './signature-pad';
import { Translated } from '../types/route';

const PracticeFormContainer = styled.div`
  .practice-form {
    padding: 0 2rem 1.1rem 2rem;
    margin-bottom: 10rem;
  }

  .close-button {
    --color: var(--ion-color-dark-text);
    z-index: 2;
  }

  p.supertitle {
    margin-top: 4.2rem;
    margin-bottom: 2rem;
  }

  p.small-text {
    margin-bottom: 2.1rem;
  }

  .inputs {
    margin-bottom: 1.2rem;
  }

  .inputs textarea {
    height: 9rem;
    --padding-top: 0;
  }

  .teacher-signature-pad {
    margin-top: 2.5rem;
  }

  .signature-container {
    height: 9.1rem;
    border-radius: 0.5rem;
    margin-bottom: 1.7rem;
  }

  .button-container {
    padding: 1rem 2rem 0 2rem;
    background-color: white;
    height: 8rem;
  }
`;

interface PracticeFormProps extends Translated {
  data: any;
  isEdit: boolean;
  isStart: boolean;
  uid: string;
  close: () => void;
  onConfirm: (action: 'start' | 'end' | 'lastEdited', formResult: PracticeFormData) => void;
  practiceId: string;
  action: 'start' | 'end' | 'lastEdited';
}

export type PracticeFormData = {
  studentSignature?: string;
  teacherSignature?: string;
  kmEnd?: string;
  kmStart?: string;
  exercise?: string;
  anotations?: string;
};
interface PracticeFormState {
  form: PracticeFormData;
  showSignaturePad: boolean;
  signaturePad: keyof PracticeFormData;
}
class PracticeForm extends React.Component<PracticeFormProps, PracticeFormState> {
  public state: PracticeFormState = {
    form: {},
    showSignaturePad: false,
    signaturePad: 'studentSignature'
  };

  componentDidMount() {
    const { data } = this.props;
    if (!Utils.isEmpty(data)) {
      this.setState({ form: data });
    }
  }

  saveSignature(field: string, signature: string) {
    const { uid, practiceId } = this.props;
    return UserService.storeSignatureAndGetURL(uid, `${field}-${practiceId}`, signature);
  }

  setFormField(field: string, value: any) {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [field]: value
      }
    }));
  }

  isValid(form: PracticeFormData) {
    const { isStart } = this.props;
    const required = ['kmStart', 'studentSignature', 'teacherSignature'];
    if (!isStart) required.push('kmEnd');
    if (required.some((key) => !(form as any)[key])) return false;
    return true;
  }

  onConfirm(form: PracticeFormData) {
    const { action } = this.props;
    const promises = [];
    switch (action) {
      case 'start':
        form.studentSignature &&
          promises.push(this.saveSignature('studentSignature', form.studentSignature));
        form.teacherSignature &&
          promises.push(this.saveSignature('teacherSignature', form.teacherSignature));
        break;
      default:
        break;
    }

    Promise.all(promises).then((result) => {
      let finalForm = { ...form };
      if (result.length > 0) {
        finalForm = {
          ...finalForm,
          studentSignature: result[0],
          teacherSignature: result[1]
        };
      }
      this.props.onConfirm(action, finalForm);
      this.props.close();
    });
  }

  setBoldText() {
    const { isStart, isEdit, t } = this.props;
    if (isEdit) return t('boldTextEdit');
    else if (isStart) return t('boldText');
    return t('boldTextFinal');
  }

  setText() {
    const { isStart, isEdit, t } = this.props;
    if (isEdit) return t('editText');
    else if (isStart) return t('initialText');
    return t('finalText');
  }

  openSignaturePad(type: keyof PracticeFormData) {
    this.setState({ signaturePad: type, showSignaturePad: true });
  }

  closeSignaturePad() {
    this.setState({ showSignaturePad: false });
  }

  setSignatureField(signaturePad: keyof PracticeFormData, signature: string | null) {
    this.setFormField(signaturePad, signature);
    this.closeSignaturePad();
  }

  render() {
    const { isStart, t } = this.props;
    const { form, signaturePad, showSignaturePad } = this.state;
    return (
      <PracticeFormContainer className="height-100 overflow-auto">
        <div className="practice-form flex-vertical between height-100">
          <IonButton
            className="absolute right margin-0 close-button"
            fill="clear"
            onClick={() => this.props.close()}
          >
            <IonIcon icon={close} />
          </IonButton>
          <div>
            <p className="supertitle semibold margin-0">
              {this.setBoldText()} {this.setText()}
            </p>
            <div className="inputs">
              <Input
                className="practice-form-input-1 margin-0 main-text dark"
                label={`${t('initialKm')}*`}
                value={form.kmStart}
                type="number"
                onChange={(val) => this.setFormField('kmStart', val)}
              />
            </div>
            <div className="inputs">
              <Input
                className="margin-0 main-text dark"
                disabled={isStart}
                label={`${t('finalKm')}*`}
                value={form.kmEnd}
                type="number"
                onChange={(val) => this.setFormField('kmEnd', val)}
              />
            </div>
            <div className="inputs">
              <Input
                className="margin-0 main-text dark"
                label={t('exercise')}
                value={form.exercise}
                onChange={(val) => this.setFormField('exercise', val)}
              />
            </div>
            <div className="inputs">
              <Input
                isTextArea
                className="margin-0 main-text dark"
                label={t('anotations')}
                value={form.anotations}
                onChange={(val) => this.setFormField('anotations', val)}
              />
            </div>
          </div>
          <div className="flex-vertical">
            <div className={`${!isStart ? 'disabled' : ''}`}>
              <div className="flex between align-items-center">
                <span className="medium-important light">{`${t('studentSignature')}*`}</span>
                {isStart ? (
                  <IonButton
                    className="main-text primary medium padding-0"
                    onClick={() => this.openSignaturePad('studentSignature')}
                    fill="clear"
                    size="small"
                  >
                    {form.studentSignature ? t('editSignature') : t('addSignature')}
                  </IonButton>
                ) : null}
              </div>
              <div
                className="signature-container regular-border"
                onClick={() => this.openSignaturePad('studentSignature')}
              >
                {form.studentSignature ? (
                  <img
                    className="width-50 height-100 block margin-auto"
                    src={form.studentSignature}
                    alt={t('studentSignature')}
                  />
                ) : null}
              </div>
            </div>
            <div className={`${!isStart ? 'disabled' : ''}`}>
              <div className="flex between align-items-center">
                <span className="medium-important light">{`${t('teacherSignature')}*`}</span>
                {isStart ? (
                  <IonButton
                    className="main-text primary medium padding-0"
                    onClick={() => this.openSignaturePad('teacherSignature')}
                    fill="clear"
                    size="small"
                  >
                    {form.teacherSignature ? t('editSignature') : t('addSignature')}
                  </IonButton>
                ) : null}
              </div>
              <div
                className="signature-container regular-border"
                onClick={() => this.openSignaturePad('teacherSignature')}
              >
                {form.teacherSignature ? (
                  <img
                    className="width-50 height-100 block margin-auto"
                    src={form.teacherSignature}
                    alt={t('teacherSignature')}
                  />
                ) : null}
              </div>
            </div>
            <p className="small-text margin-0">{`*${t('mandatoryField')}`}</p>
          </div>
          <div className="width-100 button-container absolute bottom left">
            <LoadingButton
              className="width-100 main-button regular-button-text"
              disabled={!this.isValid(form)}
              onClick={() => this.onConfirm(form)}
            >
              {isStart ? <IonIcon slot="end" icon={car} /> : null}
              {isStart ? t('start') : t('save')}
            </LoadingButton>
          </div>
        </div>
        <IonModal
          isOpen={showSignaturePad}
          onDidDismiss={() => this.closeSignaturePad()}
          cssClass="signature-pad-modal"
        >
          <SignaturePad
            className="teacher-signature-pad"
            title={signaturePad}
            value={form[signaturePad] || ''}
            save={(signature) => this.setSignatureField(signaturePad, signature)}
          />
        </IonModal>
      </PracticeFormContainer>
    );
  }
}

export default withTranslation('practice-form')(PracticeForm);
