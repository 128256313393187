import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IonAvatar, IonLabel, IonItem, IonIcon } from '@ionic/react';
import { withTranslation, useTranslation } from 'react-i18next';
//@ts-ignore
import { stylesheet } from 'stylesheet-decorator/react';
import { DateUtils } from '../services/date';
import { isEllipsisActive } from '../utils';

import Avatar from './avatar';

import {
  lock,
  dots,
  event_available,
  event_busy,
  clock_solid,
  location_grey
} from '../assets/icons';
import { Translated } from '../types/route';
import { BookedSlotWithRange, PracticeUser } from '../types/practice';

interface PracticeSlotProps {
  cellHeight: number;
  practice: BookedSlotWithRange;
  onSlotClicked: () => void;
  teacherPermissions: {
    cancel?: boolean;
    assign?: boolean;
    lock?: boolean;
  };
  onDotsClicked: () => void;
  currentDay: string;
  isMulticar: boolean;
}

interface PracticeSlotState {
  showLockReason: boolean;
}
const PracticeSlot: React.FC<PracticeSlotProps> = ({
  cellHeight,
  practice,
  onSlotClicked,
  teacherPermissions,
  onDotsClicked,
  currentDay,
  isMulticar
}) => {
  const [showLockReason, setShowLockReason] = useState(false);
  const [ellipsis, setEllipsis] = useState(false);
  const { t } = useTranslation('calendar');
  const duration = DateUtils.getDuration(practice.start, practice.end);
  const slotHeight = (cellHeight * duration) / 60;
  const currMinutes = DateUtils.getMinutes(practice.start);
  const currHour = DateUtils.getHour(practice.start);
  const slotPosition = (cellHeight * currMinutes) / 60 + (currHour - 6) * cellHeight;
  const slotTextRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (slotTextRef.current) {
      setEllipsis(isEllipsisActive(slotTextRef.current));
    }
  }, []);

  const getName = (user: PracticeUser) => {
    if (user.lastName) {
      return [user.name, user.lastName].join(' ').toLowerCase();
    }
    return user.name.toLowerCase();
  };

  const setSlotAvatar = (slot: BookedSlotWithRange) => {
    switch (getSlotStatus(slot)) {
      case 'free':
        return <IonIcon src={event_available} className="eventAvailableIcon icon block" />;
      case 'pastFree':
        return <IonIcon src={event_busy} className="icon block" />;
      case 'locked':
        return <IonIcon src={lock} className="lockIcon icon block" />;
      default:
        return;
    }
  };

  const setSlotLabel = (slot: BookedSlotWithRange) => {
    return [slot.start, t('to'), slot.end].join(' ');
  };

  const setSlotText = (slot: BookedSlotWithRange) => {
    if (!slot.user) {
      if (!DateUtils.isFuture(currentDay, slot.start)) return t('pastFree');
      return t('free');
    }
    if (slot.user && slot.user.role !== 'school') {
      return getName(slot.user) || slot.user.email;
    }
    if (slot.user && slot.user.role === 'school') {
      return slot.reason;
    }
    return [slot.start, t('to'), slot.end].join(' ');
  };

  const getSlotStatus = (practice: BookedSlotWithRange) => {
    if (isLocked(practice)) return 'locked';
    else if (isBooked(practice)) return 'booked';
    else if (!isBooked(practice) && !DateUtils.isFuture(currentDay, practice.start))
      return 'pastFree';
    else return 'free';
  };

  const isLocked = (practice: BookedSlotWithRange) => {
    return practice.user && practice.user.role === 'school' && practice.status !== 'pre-booked';
  };

  const isBooked = (practice: BookedSlotWithRange) => {
    return practice.user && practice.user.role !== 'school';
  };

  const toggleLockReason = () => {
    setShowLockReason(!showLockReason);
  };

  const isNotAppeared = (practice: BookedSlotWithRange) => {
    return practice.notAppeared;
  };

  const shouldShowDots = (practice: BookedSlotWithRange) => {
    const { cancel, lock, assign } = teacherPermissions;

    //If teacher doesn't have any permission and is past day shouldn't show dots
    if ((!cancel && !lock && !assign) || DateUtils.isPastDay(currentDay)) return false;

    //If is booked and is future, check cancel permission
    if (isBooked(practice) && DateUtils.isFuture(currentDay, practice.start)) return cancel;

    //If isn't booked or is locked and is future, check lock permission
    if (!isBooked(practice) && DateUtils.isFuture(currentDay, practice.start)) {
      if (isLocked(practice)) return lock;
      if (!isLocked(practice)) return assign || lock;
    }

    //Either return false
    return false;
  };

  const onDotsClick = () => {
    if (showLockReason) toggleLockReason();
    onDotsClicked();
  };

  const renderPrebookCountdown = (slot: BookedSlotWithRange) => {
    if (
      !slot.expires ||
      slot.status !== 'pre-booked' ||
      (slot.expires && slot.expires < Date.now())
    )
      return;
    const now = DateUtils.createDateTime.local();
    const end = DateUtils.createDateTime.fromMillis(slot.expires);

    return (
      <span className="countdown semibold">
        <IonIcon icon={clock_solid} />
        {Math.ceil(end.diff(now, 'minutes').toObject().minutes || 0)}&apos;
      </span>
    );
  };

  const handleSlotClick = (practice: BookedSlotWithRange) => {
    if (isLocked(practice) && practice.status !== 'pre-booked') {
      return toggleLockReason();
    }

    if (practice.status === 'pre-booked') return;

    return onSlotClicked();
  };

  return (
    <PracticeSlotContainer>
      <div
        className={`flex between practice-item absolute width-100
        ${isLocked(practice) ? 'locked' : ''}
        ${showLockReason ? 'flex-vertical show-reason' : ''}
      `}
        style={{ height: slotHeight - 4, top: slotPosition }}
      >
        <IonItem
          lines="none"
          class="ion-no-padding flex align-items-center"
          onClick={(evt) => {
            if (evt.target && (evt.target as HTMLElement).className.includes('dots')) return;
            handleSlotClick(practice);
          }}
        >
          {isBooked(practice) ? (
            <div className="relative">
              {isNotAppeared(practice) && duration >= 45 ? (
                <div className="not-presented round absolute">
                  <span>{t('notAppeared')}</span>
                </div>
              ) : null}
              <Avatar className={`${duration < 45 ? 'scaled' : ''}`} user={practice.user} />
            </div>
          ) : (
            <IonAvatar
              className={`avatar relative flex ${duration < 45 ? 'scaled' : ''} ${getSlotStatus(
                practice
              )}`}
            >
              {setSlotAvatar(practice)}
            </IonAvatar>
          )}
          <IonLabel class="flex align-items-center between width-100">
            <div
              className={`${
                duration < 45
                  ? 'flex align-items-baseline width-100 label-small hidden ellipsis'
                  : 'flex-vertical hidden ellipsis'
              }`}
            >
              <p
                className={`slot-label medium hidden ellipsis ${
                  duration < 45 ? 'medium-important' : ''
                } ${isBooked(practice) ? 'capitalize' : ''}`}
              >
                {setSlotText(practice)} {duration >= 45 ? renderPrebookCountdown(practice) : null}
              </p>
              <span
                ref={slotTextRef}
                className={`text-light medium-text-small hidden ellipsis block ${
                  isBooked(practice) ? 'capitalize' : ''
                }`}
              >
                {setSlotLabel(practice)}
                {isMulticar && practice.checkpoint && (
                  <span className="checkpoint text-light">
                    <IonIcon src={location_grey} className="icon" />
                    <span>{practice.checkpoint.name}</span>
                  </span>
                )}
              </span>
            </div>
            <div className="flex actions-wrapper">
              {duration < 45 ? renderPrebookCountdown(practice) : null}
              {isNotAppeared(practice) && duration < 45 ? (
                <div className="not-presented round">
                  <span>{t('notAppeared')}</span>
                </div>
              ) : null}
              {shouldShowDots(practice) ? (
                <IonIcon icon={dots} className="pointer dots" onClick={() => onDotsClick()} />
              ) : null}
            </div>
          </IonLabel>
        </IonItem>
        {showLockReason ? (
          <div>
            <hr />
            <div>
              <p className="small-paragraph-title uppercase">{t('lockReason')}</p>
              <p className="small-paragraph-text reason">{practice.reason}</p>
            </div>
          </div>
        ) : null}
      </div>
    </PracticeSlotContainer>
  );
};

const PracticeSlotContainer = styled.div`
  p {
    margin: 0;
  }

  .actions-wrapper {
    align-items: center;
    width: auto;
  }
  .practice-item {
    box-shadow: 0px 1px 6px #00000029;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.5rem;
    border-radius: 0.8rem;
    width: calc(100% - 1.5vw);
    background-color: white;
    z-index: 3;
    padding-left: 1.2rem;
  }

  .practice-item.locked {
    background-color: #e8e8e8;
    box-shadow: none;
  }

  .avatar {
    margin-right: 1.4rem;
    width: 4.8rem;
    height: 4.8rem;
  }

  .avatar.free {
    background-color: var(--ion-color-light-green);
  }

  .avatar.pastFree {
    background-color: var(--ion-color-light-silver);
  }

  .avatar.locked {
    background-color: var(--ion-color-silver);
  }

  .avatar.booked {
    background-color: #46e2ff;
  }

  .avatar .icon {
    width: 2.4rem;
    height: 2.4rem;
    margin: auto;
  }

  .eventAvailableIcon {
    fill: var(--ion-color-dark-green);
  }

  .lockIcon {
    fill: #525252;
  }

  .scaled {
    transform: scale(0.7);
    margin-right: 0;
    margin-left: -0.7rem;
    padding: 0;
  }

  .scaled .avatar {
    margin-right: 0;
  }

  ion-item {
    --background: transparent;
    width: 100%;
    --inner-padding-end: 1rem;
  }

  ion-item::part(native) {
    width: 100%;
  }

  ion-label {
    margin: 0;
  }

  .checkpoint {
    margin-left: 0.7rem;
    position: relative;
  }

  .checkpoint ion-icon {
    height: 1.3rem;
    position: relative;
    top: 1px;
  }

  ion-label > * {
    margin-right: 0.6rem !important;
  }

  .label-small {
    margin-left: 0.5rem;
  }

  p.slot-label {
    color: var(--ion-color-dark-text);
    margin-right: 0.7rem;
    width: max-content;
    margin-bottom: 0.5rem;
  }

  .text-light {
    color: var(--ion-color-light-text);
  }

  .show-reason {
    height: auto !important;
    z-index: 10;
    justify-content: initial;
    padding-right: 1rem;
  }

  .show-reason ion-item {
    margin-top: 0.5rem;
    margin-bottom: 0.7rem;
    --inner-padding-end: 0;
  }

  .show-reason hr {
    margin-bottom: 1.2rem;
    width: 100%;
  }

  .show-reason .reason {
    margin-top: 0.2rem !important;
    margin-bottom: 1rem;
  }

  .not-presented {
    z-index: 1;
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 3px #fff;
    right: 0.5rem;
    top: -0.2rem;
    border-radius: 100%;
    font-size: 1rem;
    color: #fff;
    background: #404040;
    padding-left: 1px;
    padding-bottom: 1px;
  }

  .not-presented > span {
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  ion-icon.dots {
    font-size: 1.6rem;
    right: 1.6rem;
  }

  .countdown {
    background: #404040;
    color: #fff;
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;
    padding: 0.2rem 0.7rem;
    border-radius: 30px;
    margin-left: 0.7rem;
    height: 2rem;
  }

  .countdown ion-icon {
    margin-right: 0.4rem;
  }
`;

export default PracticeSlot;
