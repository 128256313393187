import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';

import mask from '../assets/icons/mask.svg';
import hands from '../assets/icons/hands.svg';
import fan from '../assets/icons/fan.svg';
import sanitizer from '../assets/icons/sanitizer.svg';

const PracticeInstructionsContainer = styled.div`
  padding 0 1.6rem;

  hr {
    background-color: rgba(112, 112, 112, 0.3);
    margin: 0;
    width: 0.75rem;
  }

  h3 {
    margin-top: 2.8rem;
  }

  .instruction-icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  .instruction-icon-container {
    width: 4rem;
    height: 4rem;
    background-color: var(--ion-color-light-gray);
    margin-right: 1.9rem;
  }

`;

const PracticeInstructions: React.FC = () => {
  const { t } = useTranslation('instructions');
  const icons = [mask, hands, fan, sanitizer];
  const iconNames = ['mask', 'hands', 'fan', 'sanitizer'];
  return (
    <PracticeInstructionsContainer className="flex-vertical height-100">
      <h3 className="uppercase section-title">{t('title')}</h3>
      <div className="flex-vertical even height-100">
        {icons.map((icon, i) => (
          <div key={i} className="flex align-items-center">
            <div className="flex align-items-center instruction-icon-container circle">
              <IonIcon src={icon} class="icon circle block instruction-icon margin-auto" />
            </div>
            <span className="item-subtitle">{t(iconNames[i] + 'Text')}</span>
          </div>
        ))}
      </div>
    </PracticeInstructionsContainer>
  );
};

export default PracticeInstructions;
