import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { IntercomProvider } from 'react-use-intercom';

import './index.css';

import './i18n';
import config from './config';
import { DriboProvider } from './contexts/driboContext';
import { LoadingScreen } from './components/loading-screen';

const INTERCOM_APP_ID = config.intercom.appId;

ReactDOM.render(
  <DriboProvider>
    <IonApp>
      <IonReactRouter>
        <Suspense fallback={<LoadingScreen />}>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <App />
          </IntercomProvider>
        </Suspense>
      </IonReactRouter>
    </IonApp>
  </DriboProvider>,
  document.getElementById('root')
);
