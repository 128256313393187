import React from 'react';
import styled from 'styled-components';

import { DriboStudent } from '../types/user';
import { DriboPracticeDetail } from '../types/practice';

import { DateUtils } from '../services/date';

import { IonModal, IonButton } from '@ionic/react';
import PracticeSummaryCard from './practice-summary-card';

const CustomAlertContainer = styled.div`
  h2 {
    padding: 0 4rem;
  }

  p {
    padding: 0 3rem;
  }

  .button-section {
    margin-top: 2rem;
  }

  ion-button.cancel {
    margin-top: 1rem;
  }
`;

interface CustomAlertProps {
  show: boolean;
  user: DriboStudent;
  practice?: DriboPracticeDetail;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  text: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  show,
  user,
  practice,
  onConfirm,
  onCancel,
  title,
  text,
  confirmButtonText,
  cancelButtonText
}) => {
  return (
    <IonModal isOpen={show} cssClass="custom-alert-modal center">
      <CustomAlertContainer className="flex-vertical between height-100">
        <div className="flex-vertical">
          <h2 className="title-1 semibold dark">{title}</h2>
          <p className="main-text-dark">{text}</p>
        </div>
        {practice ? (
          <PracticeSummaryCard
            user={user}
            date={DateUtils.fromISOToLocal(practice.practice.date)}
            start={practice.practice.start}
            end={practice.practice.end}
            mode="col"
          />
        ) : null}
        <div className="flex-vertical button-section">
          <IonButton onClick={() => onConfirm()} className="alert-button button-text white">
            {confirmButtonText}
          </IonButton>
          <IonButton
            onClick={() => onCancel()}
            fill="clear"
            className="button-text semibold cancel"
          >
            {cancelButtonText}
          </IonButton>
        </div>
      </CustomAlertContainer>
    </IonModal>
  );
};

export default CustomAlert;
