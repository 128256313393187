import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonAvatar,
  IonMenu,
  IonSelect,
  IonSelectOption,
  IonMenuToggle
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { power } from 'ionicons/icons';
import { car, person_search, headset_mic, event } from '../assets/icons/index';
import School from '../services/school';
import styled from 'styled-components';
import userService from '../services/user';
import { useDribo } from '../contexts/driboContext';
import { AuthContextShape } from '../contexts/authContext';
import { useIntercom } from 'react-use-intercom';

interface AppPage {
  url: string;
  title: string;
  icon: string;
}

const MenuContainer = styled.div`
  ion-item:not(.menu-header) {
    color: var(--ion-color-light-text);
    margin: 1.6rem 0;
  }

  ion-item:not(.menu-header)::part(native) {
    padding: 0 1.6rem;
  }

  ion-icon.car {
    width: 2.8rem;
    height: 2.8rem;
  }

  ion-item.car-select {
    --border-color: rgba(10, 10, 10, 0.1);
    margin-top: 1.3rem;
    --inner-padding-bottom: 1.3rem;
  }

  ion-item.menu-header {
    padding: 1.6rem 1.6rem 0 1.6rem;
    margin-top: 1.6rem;
  }

  ion-item.menu-header::part(native) {
    padding: 0;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(10, 10, 10, 0.1);
  }

  .menu-title {
    line-height: 3rem;
  }

  ion-icon {
    margin-right: 1.3rem;
    width: 2.4rem;
    height: 2.4rem;
    color: var(--ion-color-dark-text);
  }

  ion-label.main-text {
    color: var(--ion-color-dark-text);
  }

  .selected ion-icon {
    color: var(--ion-color-primary);
  }

  .selected ion-label {
    color: var(--ion-color-primary);
  }

  .logout {
    bottom: 1rem;
  }

  ion-select {
    width: 100%;
  }

  .car-select {
    color: var(--ion-color-dark-text);
  }
`;

const Menu: React.FC<AuthContextShape> = (props) => {
  const { selectedCar, setSelectedCar } = useDribo();
  const { loggedUser } = props;
  const { show } = useIntercom();

  const location = useLocation();
  const { t } = useTranslation('menu');
  useEffect(() => {
    (async () => {
      await getTeacherCars();
    })();
  }, [loggedUser]);
  const [cars, setCars] = useState<any[]>([]);

  const appPages: AppPage[] = [
    {
      title: t('calendar'),
      url: '/calendar',
      icon: event
    },
    {
      title: t('search'),
      url: '/search',
      icon: person_search
    }
  ];

  const shouldDisableMenu = () => {
    return location.pathname === '/login';
  };

  const getTeacherCars = async () => {
    if (loggedUser) {
      const { schoolUid, uid } = loggedUser;
      const cars = await School.fetchCarsForTeacher(schoolUid, uid);
      setCars(cars);
      const firstCar = cars && cars[0].id;
      const carId = selectedCar || firstCar;
      setSelectedCar(carId);
    }
  };

  const changeCar = (e: CustomEvent) => {
    setSelectedCar(e.detail.value);
  };

  const logoutUser = async () => {
    await userService.logout();
  };

  const customActionSheetOptions = {
    cssClass: 'car-options'
  };

  const openIntercom = () => {
    show();
  };

  return (
    <IonMenu disabled={shouldDisableMenu()} id="menu" contentId="main" type="overlay">
      <IonContent>
        <MenuContainer>
          <IonList id="main-menu">
            <IonItem lines="none" className="menu-header">
              <IonAvatar className="large menu-avatar" slot="start">
                <img
                  className="item-list-pic"
                  src={loggedUser ? loggedUser.photo : ''}
                  alt="User avatar"
                />
              </IonAvatar>
              <span className="menu-title supertitle">
                {t('hello')}, <b className="bold">{loggedUser ? loggedUser.name : ''}</b>!
              </span>
            </IonItem>
            <IonItem className="car-select">
              <IonIcon className="car" icon={car} />
              {selectedCar && cars && (
                <IonSelect
                  className="car-select"
                  cancelText={t('cancel')}
                  mode="md"
                  interface="action-sheet"
                  onIonChange={(e) => changeCar(e)}
                  value={selectedCar}
                  interfaceOptions={customActionSheetOptions}
                >
                  {cars!.map((car) => {
                    return (
                      <IonSelectOption key={car.id} value={car.id}>
                        {car.name || car.plate}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )}
            </IonItem>
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={location.pathname === appPage.url ? 'selected' : ''}
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon icon={appPage.icon} />
                    <IonLabel className="main-text">{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
            {loggedUser && loggedUser.supportEnabled && (
              <IonMenuToggle key="support-menu" autoHide={false}>
                <IonItem
                  button
                  onClick={() => {
                    openIntercom();
                  }}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon icon={headset_mic} />
                  <IonLabel className="main-text">{t('talk')}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}
          </IonList>
          <IonItem
            className="logout absolute"
            onClick={logoutUser}
            routerDirection="none"
            lines="none"
            detail={false}
          >
            <IonIcon slot="start" icon={power} />
            <IonLabel className="main-text">{t('logout')}</IonLabel>
          </IonItem>
        </MenuContainer>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
