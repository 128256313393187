const production: AppConfig = {
  firebase: {
    apiKey: 'AIzaSyByWOvMpZdigvAyTYQu_GhZz_hkBtW5ygk',
    authDomain: 'dribo-141f3.firebaseapp.com',
    databaseURL: 'https://dribo-141f3.firebaseio.com',
    projectId: 'dribo-141f3',
    storageBucket: 'dribo-141f3.appspot.com',
    messagingSenderId: '1023519471968'
  },
  functions: 'https://us-central1-dribo-141f3.cloudfunctions.net',
  intercom: {
    appId: '44496380-5dd1-44c6-9f16-63c48ff9f78f'
  }
};

export default production;

export interface AppConfig {
  firebase: FirebaseConfig;
  credentials?: DevCredentials;
  functions: string;
  i18n?: i18nConfig;
  intercom: any;
}

type i18nConfig = {
  debug?: boolean;
};

type DevCredentials = {
  email: string;
  password: string;
};

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}
