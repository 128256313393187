import React from 'react';
import { IonButton, IonSpinner } from '@ionic/react';
import styled from 'styled-components';

interface LoadingButtonProps {
  onClick: (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => Promise<void> | void;
  disabled?: boolean;
  expand?: 'full' | 'block';
  className?: string;
}

type IonButtonProps = React.ComponentProps<typeof IonButton>;

class LoadingButton extends React.Component<LoadingButtonProps & IonButtonProps> {
  mounted = true;

  state = {
    loading: false
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleClick(evt: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) {
    if (this.state.loading) return;
    const prom = Promise.resolve(this.props.onClick(evt));
    this.setState({ loading: true });
    prom.then(() => this.mounted && this.setState({ loading: false }));
  }

  render() {
    const { onClick, children, ...props } = this.props;

    return (
      <StyleContainer>
        <IonButton
          onClick={(evt) => this.handleClick(evt)}
          {...props}
          className={`button-round ${props.className}`}
          disabled={this.state.loading || this.props.disabled}
        >
          {this.state.loading ? (
            <div className="spinner-wrapper">
              <IonSpinner className="spinner" />
            </div>
          ) : (
            children
          )}
        </IonButton>
      </StyleContainer>
    );
  }
}

export default LoadingButton;
const StyleContainer = styled.div`
  ion-button {
    --border-radius: 8px;
    font-weight: 300;
    font-size: 1.7rem;
    text-transform: none;
    letter-spacing: 0;
    height: 56px;
    opacity: 1;
    margin: 0;
  }

  .button-native {
    border-radius: 5px !important;
  }

  ion-button.button-disabled {
    --box-shadow: none;
  }

  .spinner {
    top: 0;
  }

  .spinner-wrapper {
    height: 28px;
  }
`;
