import React from 'react';
import styled from 'styled-components';
import { IonFabButton, IonIcon } from '@ionic/react';
import { DateUtils } from '../services/date';
import { chevronBackSharp, chevronForwardSharp } from 'ionicons/icons';

const CalendarHeaderContainer = styled.div`
  ion-fab-button {
    --box-shadow: none;
  }

  .month-title {
    color: var(--ion-color-dark-text);
    margin-bottom: 1.6rem;
  }

  .weekday {
    margin-bottom: 0.2rem;
  }

  .day-number {
    line-height: 2.5rem;
  }

  .arrow {
    color: var(--ion-color-light-text);
    height: 1.5rem;
    width: 2rem;
    margin-top: 1rem;
  }

  .past {
    color: var(--ion-color-light-text);
  }
`;

interface CalendarHeaderProps {
  day: string;
  onDayChange: (day: string) => void;
  className: string;
}
const CalendarHeader: React.FC<CalendarHeaderProps> = (props) => {
  const { onDayChange } = props;
  const classes = props.className;
  const day = props.day;
  const prevWeek = DateUtils.getPrevWeek(day);
  const nextWeek = DateUtils.getNextWeek(day);
  const days = DateUtils.getWeekInfo(day).map((d) => {
    const selectedDay = DateUtils.isSameDay(d.iso, day);
    const past = DateUtils.isPast(d.iso);
    return (
      <div
        key={d.iso}
        className={`center flex-vertical ${selectedDay ? 'selected' : ''} ${
          past ? 'past' : 'dark'
        }`}
      >
        <span className="small semibold light uppercase weekday">{d.name}</span>
        <IonFabButton
          class="ion-fab-button-smaller"
          onClick={() => onDayChange(d.iso)}
          color={selectedDay ? 'primary' : 'white'}
          className="small uppercase"
        >
          <span className="day-number">{d.number}</span>
        </IonFabButton>
      </div>
    );
  });
  return (
    <CalendarHeaderContainer className={classes}>
      <h1 className="month-title center supertitle semibold dark capitalize">
        {DateUtils.getMonthName(day)}
      </h1>
      <div className="flex-horizontal even">
        <IonIcon onClick={() => onDayChange(prevWeek)} className="arrow" icon={chevronBackSharp} />
        {days}
        <IonIcon
          onClick={() => onDayChange(nextWeek)}
          className="arrow"
          icon={chevronForwardSharp}
        />
      </div>
    </CalendarHeaderContainer>
  );
};

export default CalendarHeader;
