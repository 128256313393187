import React from 'react';
import { IonAvatar } from '@ionic/react';
import styled from 'styled-components';

import Utils from '../utils';
import { DriboStudent } from '../types/user';

const COLOR_COUNT = 8;

const AvatarContainer = styled.div`
  .avatar {
    margin-right: 1.4rem;
    width: 4.8rem;
    height: 4.8rem;
  }

  &.x-large > .avatar {
    width: 6rem;
    height: 6rem;
  }

  .avatar .initials {
    width: max-content;
    height: 2.4rem;
    margin: auto;
    font-size: 1.6rem;
    line-height: 2.7rem;
    text-align: center;
    color: white;
    font-weight: 600;
    white-space: nowrap;
  }

  .hash {
    font-size: 1.4rem;
    margin-right: 0.2rem;
  }
`;

interface AvatarProps {
  className?: string;
  user?: any;
  text?: string;
  index?: number;
}
const Avatar: React.FC<AvatarProps> = (props) => {
  const { className, user, text, index } = props;

  const setAvatar = () => {
    const avatar =
      !user || !user.avatar || user.avatar.includes('default_avatar') ? 'initials' : user.avatar;
    if (avatar === 'initials') {
      if (text && text.includes('#')) {
        return (
          <div className="icon initials">
            {text ? (
              <div>
                <span className="hash">{text.slice(0, 1)}</span>
                <span>{text.split('#')[1]}</span>
              </div>
            ) : null}
          </div>
        );
      }
      return (
        <div className="icon initials">
          {text ? text.toUpperCase() : setInitials().toUpperCase()}
        </div>
      );
    }
    return <img className="item-list-pic" src={avatar} alt="User avatar" />;
  };

  const setInitials = () => {
    if (!user || !user.email) return '-';
    else if (!user.name) return user.email[0];
    else if (user.name && !user.lastName) return user.name[0];
    return [user.name[0], user.lastName[0]].join('');
  };

  const id = user ? user.uid : index;
  const avatarColor = Utils.getHash(id, COLOR_COUNT);
  const randomIndex = Math.floor(Math.random() * COLOR_COUNT);
  return (
    <AvatarContainer className={`${className ? className : ''}`}>
      <IonAvatar
        className={`avatar relative flex
          ${`avatar-color-${avatarColor + 1 || randomIndex}`}
        `}
      >
        {setAvatar()}
      </IonAvatar>
    </AvatarContainer>
  );
};

export default Avatar;
