import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PrivateRouteNavigationProps } from '../components/private-route';
import styled from 'styled-components';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonRadio,
  IonButtons,
  IonBackButton,
  IonButton,
  IonThumbnail,
  IonSpinner
} from '@ionic/react';

import practiceService from '../services/practices';
import userService from '../services/user';
import notificationsService from '../services/notifications';
import { DateUtils } from '../services/date';
import { DriboPractice, DriboPracticeDetail } from '../types/practice';
import { DriboStudent } from '../types/user';

import LoadingButton from '../components/loading-button';
import Avatar from '../components/avatar';
import { ActionLayout } from '../components/layouts/action-layout';
import PracticeSummaryCard from '../components/practice-summary-card';

import { location } from '../assets/icons';

const NotAppearedFormPageContainer = styled.div`
  height: 100%;

  .summary-card {
    margin: 2.5rem 0;
  }

  h2 {
    margin-bottom: 1.5rem;
    line-height: 2rem;
  }

  h2.reason-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .confirm-section {
    margin-bottom: 1rem;
  }

  .first-question {
    width: 100%;
  }

  ion-item {
    --padding-start: 0;
  }

  ion-radio {
    margin-right: 1.6rem;
    --color-checked: var(--ion-color-primary);
    --color: var(--ion-color-dark-text);
  }

  ion-radio::part(container) {
    border-width: 1px;
  }

  ion-radio.radio-checked::part(container) {
    border-width: 2px;
  }

  ion-radio::part(mark) {
    width: 0.8rem;
    height: 0.8rem;
  }

  input {
    border: none;
    border-bottom: 1px solid var(--ion-color-light-text);
    height: 3rem;
  }

  input:focus {
    border-bottom: 1px solid var(--ion-color-primary);
    outline: none;
  }

  .warning {
    margin-bottom: 6rem;
  }

  .main-button {
    margin-bottom: 1.3rem;
  }

  .reason-container {
    margin-bottom: 9.2rem;
  }

  .avatar {
    margin-right: 2rem;
  }

  .separator {
    width: 100%;
    height: 11px;
    min-height: 11px;
    background: #f4f5fa;
  }
`;

type NotAppearedFormPageProps = PrivateRouteNavigationProps;
const NotAppearedFormPage: React.FC<NotAppearedFormPageProps> = (props) => {
  const { loggedUser, history } = props;
  const { t } = useTranslation('not-appeared-form');
  const [form, setForm] = useState<Record<string, boolean | string | null>>({
    notAppearedConfirmed: null,
    reason: ''
  });
  const [answered, setAnswered] = useState<boolean>(false);
  const [otherReason, setOtherReason] = useState<string>('');
  const [formSubmitted, setFormsubmitted] = useState<boolean>(false);
  const [practice, setPractice] = useState<DriboPracticeDetail>();
  const [student, setStudent] = useState<DriboStudent>();
  const waitToastId = useRef<React.ReactText | null>(null);

  useEffect(() => {
    getPractice();
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      closeWaitToast();
    });

    return unlisten;
  }, []);

  const getPractice = () => {
    practiceService
      .fetchDetail(loggedUser.schoolUid, (props.match.params as any).pid.split(',')[0])
      .then((practice) => {
        setPractice(practice);
        getUser(practice.user);
      });
  };

  const getUser = (userId: string) => {
    return userService.fetchStudentProfile(userId).then((student) => setStudent(student));
  };

  const onFormChange = (field: string, value: boolean | string) => {
    if (field === 'notAppearedConfirmed' && !value) {
      setAnswered(true);
      if (!waitToastId.current) {
        waitToastId.current = notificationsService.showBasicToast(t('toastWarning'), {
          position: notificationsService.POSITIONS.BOTTOM_CENTER,
          className: 'not-appeared-form-toast',
          icon: location,
          transition: notificationsService.TRANSITIONS.Zoom
        });
      }
      return setForm({ ...form, notAppearedConfirmed: false, reason: '' });
    }

    closeWaitToast();

    setForm({
      ...form,
      [field]: value
    });
  };

  const shouldRenderReason = () => {
    return form.notAppearedConfirmed !== null && form.notAppearedConfirmed;
  };

  const renderButtonText = () => {
    if (!form.notAppearedConfirmed && answered) return t('understood');
    return t('notAppeared');
  };

  const disableButton = () => {
    if (form.notAppearedConfirmed === null) return true;
    else {
      if (form.notAppearedConfirmed) return form.reason === '';
      return false;
    }
  };

  const onButtonClicked = () => {
    return updatePractice().then(() => {
      if (!form.notAppearedConfirmed) return props.history.goBack();
      return props.history.replace('/actions-after-not-appeared');
    });
  };

  const updatePractice = () => {
    return Promise.all([updateUserPractice(), updateTeacherPractice()]);
  };

  const updateUserPractice = () => {
    if (practice) {
      const practices = props.match.params.pid.split(',');
      return Promise.all(
        practices.map((pid) => {
          return practiceService.updateUserPractice(practice.user, pid, {
            notAppeared: true,
            notAppearedReason: form.reason,
            otherReason: form.reason === 'other' ? otherReason : null
          });
        })
      );
    }
    return Promise.resolve();
  };

  const updateTeacherPractice = () => {
    if (practice) {
      return practiceService.updateTeacherPractice(
        loggedUser,
        practice.practice.car,
        practice.practice.date,
        practice.practice.start,
        {
          notAppeared: true
        }
      );
    }
  };

  const getCompleteName = (user: DriboStudent | undefined) => {
    return user ? userService.fullName(user) : '';
  };

  const shouldRenderSpinner = () => {
    const { loggedUser } = props;
    return typeof loggedUser !== 'object' || !student || !practice;
  };

  const closeWaitToast = () => {
    if (waitToastId.current) {
      notificationsService.closeToast(waitToastId.current);
      waitToastId.current = null;
    }
  };

  const renderForm = () => {
    return (
      <NotAppearedFormPageContainer>
        <ActionLayout
          title={t('title')}
          renderSubtitle={() => {
            if (!student || !practice) return <></>;
            return (
              <div className="summary-card">
                <PracticeSummaryCard
                  user={student}
                  date={DateUtils.fromISOToLocal(practice.practice.date)}
                  start={practice.practice.start}
                  end={practice.practice.end}
                  mode="row"
                />
              </div>
            );
          }}
          separator={!student || !practice ? false : true}
          actions={[
            {
              label: renderButtonText(),
              onClick: () => {
                return onButtonClicked();
              },
              type: 'primary',
              disabled: disableButton()
            }
          ]}
        >
          <div className="width-100">
            {shouldRenderSpinner() ? (
              <IonSpinner name="dots" />
            ) : (
              <div className="flex-vertical height-100 between form-container">
                <div className="flex-vertical confirm-section">
                  <h2 className="main-text first-question">
                    {t('firstQuestionPart')}
                    <span>
                      <b className="bold">{t('boldQuestionPart')}</b>
                    </span>
                    {t('lastQuestionPart')}
                  </h2>
                  <IonRadioGroup
                    value={form.notAppearedConfirmed}
                    onClick={(e) =>
                      onFormChange(
                        'notAppearedConfirmed',
                        (e.target as HTMLIonRadioGroupElement).value
                      )
                    }
                  >
                    <IonItem lines="none" className="main-text">
                      <IonLabel>{t('yes')}</IonLabel>
                      <IonRadio slot="start" value={true} mode="md" />
                    </IonItem>

                    <IonItem lines="none" className="main-text">
                      <IonLabel>{t('noButContact')}</IonLabel>
                      <IonRadio slot="start" value={false} mode="md" />
                    </IonItem>
                  </IonRadioGroup>
                </div>
                <div className="with-100">
                  {shouldRenderReason() ? (
                    <div className="flex-vertical reason-container">
                      <div className="separator absolute left"></div>
                      <h2 className="main-text-large semibold reason-title">{t('reason')}</h2>
                      <IonRadioGroup
                        value={form.reason}
                        onClick={(e) =>
                          onFormChange('reason', (e.target as HTMLIonRadioGroupElement).value)
                        }
                      >
                        <IonItem lines="none" className="main-text">
                          <IonLabel>{t('work')}</IonLabel>
                          <IonRadio slot="start" value="work" mode="md" />
                        </IonItem>

                        <IonItem lines="none" className="main-text">
                          <IonLabel>{t('disease')}</IonLabel>
                          <IonRadio slot="start" value="disease" mode="md" />
                        </IonItem>

                        <IonItem lines="none" className="main-text">
                          <IonLabel>{t('transport')}</IonLabel>
                          <IonRadio slot="start" value="transport" mode="md" />
                        </IonItem>

                        <IonItem lines="none" className="main-text">
                          <IonLabel>{t('weather')}</IonLabel>
                          <IonRadio slot="start" value="weather" mode="md" />
                        </IonItem>
                        <div className="flex">
                          <IonItem lines="none" className="main-text">
                            <IonLabel>{t('other')}:</IonLabel>
                            <IonRadio slot="start" value="other" mode="md" />
                          </IonItem>
                          <input
                            className="item-subtitle margin-vertical-auto"
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                          />
                        </div>
                      </IonRadioGroup>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </ActionLayout>
      </NotAppearedFormPageContainer>
    );
  };

  return renderForm();
};

export default NotAppearedFormPage;
