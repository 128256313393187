import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLabel,
  IonItem,
  IonButton,
  IonThumbnail,
  IonIcon,
  IonModal,
  IonSpinner
} from '@ionic/react';
import { car } from 'ionicons/icons';
import styled from 'styled-components';

import practiceService from '../services/practices';
import userService from '../services/user';
import schoolService from '../services/school';
import { DateUtils } from '../services/date';
import Utils from '../utils';

import PracticeForm, { PracticeFormData } from '../components/practice-form';
import PracticeInstructions from '../components/practice-instructions';
import ContactButtons from '../components/contact-buttons';
import Avatar from '../components/avatar';
import WarningMark from '../components/warning-mark';
import LoadingButton from '../components/loading-button';
import { PrivateRouteNavigationProps } from '../components/private-route';
import { Translated } from '../types/route';
import { DriboStudent } from '../types/user';
import { DriboPracticeDetail } from '../types/practice';

import { calendar, clock, location } from '../assets/icons/index';

const COLOR_COUNT = 8;

const PracticeDetailContainer = styled.div`
  .practice-card {
    padding: 1rem 1.8rem 2.5rem 1.8rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }

  .user-general-info {
    padding-bottom: 1.1rem;
    border-bottom: 1px solid var(--ion-color-light-border);
    margin-bottom: 1.2rem;
    --inner-padding-start: 0;
    --padding-start: 0;
  }

  .contact {
    margin-top: 3rem;
  }

  .contact-missing {
    margin-top: 2.2rem;
  }

  .user-avatar {
    width: 5.8rem;
    height: 5.8rem;
    margin-right: 2rem;
  }

  .practice-detail-thumbnail ion-avatar {
    width: 5.8rem;
    height: 5.8rem;
  }

  .practice-card-button {
    --border-width: 1px;
    --border-radius: 0.7rem;
    --border-color: var(--ion-color-black);
    --color: var(--ion-color-black);
    margin: 0;
    width: 10.4rem;
    height: 3.9rem;
    --background-activated: var(--ion-color-white);
    --color-activated: var(--ion-color-black);
  }

  p.warning {
    margin: 0;
    margin-left: 0.5rem;
  }

  .practice-info {
    margin-left: 1.7rem;
    margin-right: 1.7rem;
    margin-top: 3.1rem;
  }

  .practice-info-content > div {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    border-bottom: 1px solid var(--ion-color-light-border);
  }

  .practice-info .practice-info-content > div:nth-child(1) {
    border-top: none;
    padding-top: 0;
  }

  .signatures {
    padding-top: 1rem;
  }

  .signatures img {
    width: 10rem;
    height: 6.5rem;
  }

  .buttons {
    margin-bottom: 1.8rem;
    padding: 0 1.6rem;
  }

  .not-appeared {
    margin-top: 6.1rem;
    margin-bottom: 8rem;
  }

  .not-appeared-chip {
    background: var(--ion-color-dark-text);
    border-radius: 50px;
    width: max-content;
    height: 2.8rem;
    margin-bottom: 1.4rem;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    padding: 0.7rem 1rem;
    font-weight: 600;
  }

  .icon-container.circle {
    padding: 0;
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 0.6rem;
  }

  .icon-container.circle .calendar {
    font-size: 1.2rem;
  }

  .warning-mark {
    border: 2px solid white;
    width: 2.1rem;
    height: 2.1rem;
    text-align: center;
    line-height: 1.6rem;
    right: -4px;
  }
`;

type PracticeDetailForm = { start?: string; end?: string } & PracticeFormData;
interface PracticeDetailPageState {
  form: PracticeDetailForm;
  practice?: DriboPracticeDetail;
  student?: DriboStudent;
  modal?: string;
  missingDocs?: any;
}
interface PracticeDetailPageProps extends PrivateRouteNavigationProps, Translated {}
class PracticeDetailPage extends React.Component<PracticeDetailPageProps, PracticeDetailPageState> {
  private detach: ((a?: any | null, b?: string | undefined) => any) | null = null;

  state: PracticeDetailPageState = {
    form: {}
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: PracticeDetailPageProps) {
    const { match } = this.props;
    const currentParamPid = (match.params as any).pid.split(',')[0];
    const prevParamPid = (prevProps.match.params as any).pid.split(',')[0];
    if (currentParamPid !== prevParamPid) {
      this.getData();
    }
  }

  componentWillUmount() {
    typeof this.detach === 'function' && this.detach();
  }

  getData() {
    const { loggedUser, match } = this.props;
    this.getDetail(
      loggedUser.schoolUid,
      (match.params as any).pid.split(',')[0],
      (practice: DriboPracticeDetail) => {
        this.getUser(practice.user).then(() => {
          this.getMissingDocs(practice.user, loggedUser.schoolUid);
        });
      }
    );
  }

  getDetail(teacherId: string, practiceId: string, cbk: (practice: DriboPracticeDetail) => void) {
    this.detach = practiceService.listenPracticeDetail(teacherId, practiceId, (practice) => {
      if (practice) {
        this.setState(
          {
            practice,
            form: practice.practice.details ? practice.practice.details : {}
          },
          () => {
            cbk(practice);
          }
        );
      }
    });
  }

  getUser(userId: string) {
    return userService.fetchStudentProfile(userId).then((student) => this.setState({ student }));
  }

  getMissingDocs(userId: string, schoolId: string) {
    const { student } = this.state;
    const { t } = this.props;
    if (student) {
      schoolService.fetchDocuments(userId, schoolId).then((documents) => {
        const initialDocs = ['talon', 'cm', 'examApplication', 'contract', 'traspaso'];
        const actualDocs = userService.removeUnneededDocs(student.practiceUserType, initialDocs);
        const missingDocs: any = actualDocs.reduce((acc, curr) => {
          if (!documents || !documents[curr] || !documents[curr].valid) acc.push(t(curr));
          return acc;
        }, []);
        this.setState({ missingDocs });
      });
    }
  }

  gotoStudentProfile(studentId: string) {
    this.props.history.push(`/student-profile/${studentId}`);
  }

  goToNotAppearedForm() {
    const { match } = this.props;
    const practiceId = (match.params as any).pid;
    this.props.history.push(`/not-appeared-form/${practiceId}`);
  }

  openModal(name: string) {
    this.setState({ modal: name });
  }

  closeModal() {
    this.setState({ modal: undefined });
  }

  updatePractice(userId: string, practiceId: string, form: PracticeDetailForm, action: string) {
    this.setState({ form: { ...form, [action]: Date.now() } }, () => {
      const practices = practiceId.split(',');
      const tempForm = Object.assign({}, this.state.form);
      const kmStart = tempForm.kmStart;
      const kmEnd = tempForm.kmEnd;
      if (practices.length === 1)
        return practiceService.updateUserPractice(userId, practiceId, this.state.form);
      return Promise.all(
        practices.map((pid, i) => {
          if (i === 0) {
            delete tempForm.kmEnd;
          } else if (i === practices.length - 1) {
            tempForm.kmEnd = kmEnd;
            delete tempForm.kmStart;
          } else {
            delete tempForm.kmStart;
            delete tempForm.kmEnd;
          }
          return practiceService.updateUserPractice(userId, pid, tempForm);
        })
      );
    });
  }

  onFormChange(field: string, value: any) {
    this.setState({ form: { ...this.state.form, [field]: value } });
  }

  onMainButtonClicked() {
    return this.openModal('practice-form-modal');
  }

  renderDetails() {
    return <div className="flex-vertical">{this.renderPractice()}</div>;
  }

  getCompleteName(user: DriboStudent) {
    return userService.fullName(user);
  }

  getNumPractices(completedPractices: number) {
    const { match, t } = this.props;
    const currentPractices = match.params.pid.split(',');
    if (currentPractices.length === 1) return completedPractices + 1;
    return currentPractices.map((_, i) => {
      const practiceNum = completedPractices + i + 1;
      if (i === currentPractices.length - 1) return `${t('and')} ${practiceNum}`;
      if (i === currentPractices.length - 2) return `${practiceNum} `;
      return `${practiceNum}, `;
    });
  }

  shouldShowChip() {
    const { practice } = this.state;
    const { match } = this.props;
    if (match.params.pid.split(',').length > 1) return false;
    return practice && practice.practice && practice.practice.position;
  }

  renderCard(started: boolean) {
    const { t } = this.props;
    const { student, missingDocs } = this.state;
    if (!student) return <></>;
    return (
      <div className="practice-card">
        <IonItem lines="none" class="flex align-items-center user-general-info">
          <div className="relative user-avatar">
            {missingDocs.length ? <WarningMark className="absolute right warning-mark" /> : null}
            <IonThumbnail class="practice-detail-thumbnail" slot="start">
              <Avatar user={student} />
            </IonThumbnail>
          </div>
          <IonLabel>
            <div>
              <h1 className="main-text-large item-title semibold capitalize">
                {this.getCompleteName(student)}
              </h1>
              <h2 className="item-subtitle">{`${t('completedPractices')}: ${
                student.completedPractices
              }`}</h2>
            </div>
          </IonLabel>
        </IonItem>
        {missingDocs.length ? (
          <div className="flex align-items-baseline">
            <p className="warning">
              <b className="semibold">{t('missingDocs')}:</b> {missingDocs.join(', ')}
            </p>
          </div>
        ) : null}
        <div className={`flex between ${missingDocs.length ? 'contact' : 'contact-missing'}`}>
          <IonButton
            onClick={() => this.gotoStudentProfile(student.uid)}
            fill="outline"
            class="practice-card-button medium-text"
          >
            {t('seeProfile')}
          </IonButton>
          {!started ? <ContactButtons student={student} sendWhatsapp /> : null}
        </div>
      </div>
    );
  }

  renderPractice() {
    const { t } = this.props;
    const { practice, form, student } = this.state;
    const ended = form && form.end;
    const practiceNumber = this.props.location.hash ? this.props.location.hash.split('#')[1] : null;
    const practiceColor =
      practice && practice.practice && practice.practice.includedPractices
        ? Utils.getHash(practice.practice.includedPractices[0], COLOR_COUNT) + 1
        : 0;
    return (
      <div className="practice-info">
        <div className="practice-info-content flex-vertical">
          <div className="flex between main-text">
            <span>
              {t('numPractices')}{' '}
              <span className="color-black">
                {practiceNumber
                  ? practiceNumber
                  : student
                  ? this.getNumPractices(student.completedPractices)
                  : 0}
              </span>
            </span>
            {this.shouldShowChip() ? (
              <div className={`chip small chip-color-${practiceColor}`}>
                {practice && practice.practice && practice.practice.position
                  ? practice.practice.position
                  : null}
              </div>
            ) : null}
          </div>
          <div className="flex main-text">
            <div className="flex-1 flex align-items-center">
              <div className="flex align-items-center icon-container circle">
                <IonIcon src={calendar} class="icon block margin-auto calendar" />
              </div>
              <span>
                {t('date')}{' '}
                <span className="color-black">
                  {practice ? DateUtils.fromISOToLocal(practice.practice.date) : null}
                </span>
              </span>
            </div>
            <div className="flex-1 flex align-items-center">
              <div className="flex align-items-center icon-container circle">
                <IonIcon src={clock} class="icon block margin-auto clock" />
              </div>
              <span>
                {t('time')} <span className="color-black">{(practice as any).practice.start}</span>
              </span>
            </div>
          </div>
          <div className="flex main-text">
            <div className="flex-1 flex align-items-center">
              <div className="flex align-items-center icon-container circle">
                <IonIcon src={location} class="icon block margin-auto location" />
              </div>
              <span>
                {t('startingKm')}{' '}
                <span className="color-black">{`${
                  form && form.kmStart ? `${form.kmStart}` : '-'
                }`}</span>
              </span>
            </div>
            <div className="flex-1 flex align-items-center">
              <div className="flex align-items-center icon-container circle">
                <IonIcon src={location} class="icon block margin-auto location" />
              </div>
              <span>
                {t('finalKm')}{' '}
                <span className="color-black">{`${
                  form && form.kmEnd ? `${form.kmEnd}` : '-'
                }`}</span>
              </span>
            </div>
          </div>
          <div className="flex main-text">
            <span>
              {t('exercise')} <span className="color-black">{form.exercise}</span>
            </span>
          </div>
          <div className="flex-vertical main-text">
            <span>{t('anotations')}</span>
            <span className="color-black">{form.anotations}</span>
          </div>
        </div>
        {ended ? (
          <div className="signatures flex between">
            <div className="flex align-items-center">
              <h4 className="main-text margin-0">{t('studentSignature')}</h4>
              <img src={form && form.studentSignature} alt="Firma Alumno" />
            </div>
            <div className="flex align-items-center">
              <h4 className="main-text margin-0">{t('teacherSignature')}</h4>
              <img src={form && form.teacherSignature} alt="Firma Profesor" />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  shouldRenderSpinner() {
    const { loggedUser } = this.props;
    const { student, missingDocs } = this.state;
    return typeof loggedUser !== 'object' || !student || !missingDocs;
  }

  showCarIcon(started: boolean, ended: boolean) {
    return started && !ended;
  }

  setAction(started: boolean, ended: boolean) {
    if (started) return 'end';
    else if (ended) return 'lastEdited';
    return 'start';
  }

  userNotAppeared() {
    const { practice } = this.state;
    return (
      practice &&
      practice.practice &&
      practice.practice.details &&
      practice.practice.details.notAppeared &&
      practice.practice.details.notAppearedReason
    );
  }

  renderReason() {
    const { practice } = this.state;
    const { t } = this.props;
    if (practice) {
      const otherReason = practice.practice.details.otherReason;
      const reason = practice.practice.details.notAppearedReason;
      if (otherReason) return otherReason;
      return t(reason);
    }
    return '';
  }

  showNotAppearedButton() {
    const { form } = this.state;
    const started = !!(form && form.start && !form.end);
    const ended = !!(form && form.end);
    return !started && !ended;
  }

  render() {
    const { loggedUser, match, t } = this.props;
    const { student, modal, form } = this.state;
    const started = !!(form && form.start && !form.end);
    const ended = !!(form && form.end);

    return (
      <PracticeDetailContainer>
        <IonPage id="practice-detail">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/calendar" />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {!student || this.shouldRenderSpinner() ? (
              <IonSpinner name="dots" />
            ) : (
              <div className="flex-vertical info-container height-100">
                {this.renderCard(started)}
                <div className="separator"></div>
                {started ? <PracticeInstructions /> : this.renderDetails()}
                {this.userNotAppeared() ? (
                  <div className="not-appeared center flex-vertical align-items-center">
                    <div className="not-appeared-chip uppercase">{t('notAppeared')}</div>
                    <div className="main-text">
                      {t('reason')}: <span>{this.renderReason()}</span>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-vertical buttons width-100 margin-top-auto">
                    <LoadingButton
                      onClick={() => this.onMainButtonClicked()}
                      className="practice-button regular-button-text flex-1"
                      expand="full"
                    >
                      {this.showCarIcon(started, ended) ? <IonIcon slot="end" icon={car} /> : null}
                      {started ? t('end') : ended ? t('edit') : t('start')}
                    </LoadingButton>
                    {this.showNotAppearedButton() ? (
                      <LoadingButton
                        className="button-text practice-button flex-1 not-appeared-button"
                        fill="clear"
                        onClick={() => this.goToNotAppearedForm()}
                        expand="full"
                      >
                        {t('notAppeared')}
                      </LoadingButton>
                    ) : null}
                  </div>
                )}
                <IonModal
                  isOpen={modal === 'practice-form-modal'}
                  onDidDismiss={() => this.closeModal()}
                >
                  <PracticeForm
                    close={() => this.closeModal()}
                    isStart={!started && !ended}
                    isEdit={ended}
                    action={this.setAction(started, ended)}
                    onConfirm={(start, details) =>
                      this.updatePractice(student.uid, (match.params as any).pid, details, start)
                    }
                    practiceId={(match.params as any).pid}
                    uid={loggedUser.uid}
                    data={form}
                  />
                </IonModal>
              </div>
            )}
          </IonContent>
        </IonPage>
      </PracticeDetailContainer>
    );
  }
}

export default withTranslation('practice-detail')(PracticeDetailPage);
