import React from 'react';
import { toast, Slide, Zoom, Flip, Bounce, ToastOptions, TypeOptions } from 'react-toastify';
import { IonIcon } from '@ionic/react';

import { check_green_circle, close, alert_red } from '../assets/icons/index';

export const POSITIONS = toast.POSITION;

export const TRANSITIONS = { Slide, Zoom, Flip, Bounce };

const TEMPLATE_TOAST_CONFIG: ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: 0,
  transition: Slide,
  closeButton: () => <IonIcon icon={close} />,
  icon: () => <IonIcon icon={check_green_circle} />
};

const getToastOptions = (options?: ToastOptions) => {
  if (!options) return TEMPLATE_TOAST_CONFIG;
  //We pass icon url so we have to create the ReactNode
  if (options.icon && typeof options.icon === 'string') {
    const icon = options.icon;
    options.icon = () => (
      <div className="toast-info-icon circle toast-icon-bg">
        <IonIcon icon={icon} />
      </div>
    );
  }
  return Object.assign({}, TEMPLATE_TOAST_CONFIG, options);
};

export const showBasicToast = (text: string, options?: ToastOptions) => {
  const toastOptions = getToastOptions(options);
  return toast(text, toastOptions);
};

export const showErrorToast = (text: string, options?: ToastOptions) => {
  const toastOptions = getToastOptions(options);
  toastOptions.icon = () => <IonIcon icon={alert_red} />;
  return toast(text, toastOptions);
};

export const showLoadingToast = (text: string, options?: ToastOptions) => {
  const toastOptions = getToastOptions(options);
  delete toastOptions.icon;
  return toast.loading(text, toastOptions);
};

export const updateLoadingToast = (toastId: React.ReactText, type: TypeOptions, text: string) => {
  return toast.update(toastId, {
    render: text,
    type: type,
    isLoading: false,
    icon: () => <IonIcon icon={type === 'success' ? check_green_circle : alert_red} />,
    autoClose: 3000
  });
};

export const closeToast = (toastId: React.ReactText) => {
  return toast.dismiss(toastId);
};

export default {
  POSITIONS,
  TRANSITIONS,
  showBasicToast,
  showErrorToast,
  showLoadingToast,
  updateLoadingToast,
  closeToast
};
