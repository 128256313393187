import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IonPage,
  IonSearchbar,
  IonIcon,
  IonSpinner,
  IonContent,
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonList,
  IonItem
} from '@ionic/react';
import Avatar from '../components/avatar';
import { SearchBar } from '../components/search-bar';
import User from '../services/user';
import { useTranslation } from 'react-i18next';
import utils from '../utils';
import { identity_card, phone } from '../assets/icons/index';
import { PrivateRouteNavigationProps } from '../components/private-route';
import { DriboStudent } from '../types/user';

const SearchPageContainer = styled.div`
  ion-content {
    --padding-start: 1.8rem;
    --padding-end: 1.8rem;
  }

  h1 {
    margin-top: 2rem;
    line-height: 2.6rem;
  }

  .search-form {
    margin-top: 2rem;
    z-index: 99;
    background: white;
    position: sticky;
    top: 0;
    padding-bottom: 1rem;
  }

  ion-icon {
    color: var(--ion-color-light-text);
  }

  .detail > span {
    margin-right: 2rem;
  }

  .name {
    display: block;
    margin-bottom: 0.5rem;
  }

  ion-icon {
    font-size: 1.4rem;
    position: relative;
    bottom: -1px;
  }

  ion-item {
    --padding-start: 0;
  }

  .slot-content {
    width: 100%;
    border-top: 1px solid rgba(10, 10, 10, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  ion-item:last-child .slot-content {
    border-bottom: 1px solid rgba(10, 10, 10, 0.1);
  }

  .search-results {
    margin-top: 2rem;
  }

  .searchbar-input-container {
    height: 4.8rem !important;
  }

  .searchbar-input-container input {
    padding-inline-start: 5rem !important;
    padding-inline-end: 5rem !important;
  }

  .searchbar-clear-button {
    margin-right: 1rem !important;
  }

  .ios .searchbar-input-container ion-icon {
    margin-left: 1rem !important;
  }

  .avatar {
    margin-right: 2.3rem;
  }

  .searchbar-clear-button.sc-ion-searchbar-md {
    height: 85%;
  }
`;

interface SearchPageProps extends PrivateRouteNavigationProps {
  students: DriboStudent[];
}

type DriboStudentWithDisplay = DriboStudent & { display?: string };

const SearchPage: React.FC<SearchPageProps> = (props) => {
  const { t } = useTranslation('search');
  const [students, setStudents] = useState<DriboStudentWithDisplay[]>([]);
  const [loading, setLoading] = useState(true);

  const goToHistory = (studentId: string) => props.history.push(`/student-profile/${studentId}`);

  const onSearchChange = (value?: string) => {
    setStudents(
      students.map((i: any) => {
        i.display = ['phone', 'dni', 'name', 'lastName', 'lastName2'].some((field) => {
          return (
            value &&
            i[field] &&
            utils.normalize(i[field].toString()).indexOf(utils.normalize(value)) > -1
          );
        });
        return i;
      })
    );
  };

  useEffect(() => {
    if (props.students) {
      setLoading(false);
    }
    setStudents(props.students);
  }, [props.students]);

  return (
    <SearchPageContainer>
      <IonPage id="search">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton color="dark-text" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <h1 className="supertitle semibold">{t('searchTitleBold')}</h1>
          <div className="search-form">
            <SearchBar
              disabled={loading}
              placeholder={t('search')}
              onChange={(value) => onSearchChange(value)}
            />
          </div>
          <IonList className="search-results">
            {!loading &&
              students
                .filter((s) => s.display)
                .map((student) => {
                  return (
                    <IonItem
                      button
                      onClick={() => goToHistory(student.uid)}
                      lines="none"
                      key={student.uid}
                    >
                      <Avatar user={student} />
                      <div className="slot-content">
                        <span className="main-text dark name semibold">
                          {User.fullName(student)}
                        </span>
                        <div className="detail">
                          {student.dni && (
                            <span className="main-text-small">
                              <IonIcon icon={identity_card} /> {student.dni}
                            </span>
                          )}
                          {student.phone && (
                            <span className="main-text-small">
                              <IonIcon icon={phone} /> {student.phone}
                            </span>
                          )}
                        </div>
                      </div>
                    </IonItem>
                  );
                })}
            {loading && <IonSpinner className="center" name="dots" />}
          </IonList>
        </IonContent>
      </IonPage>
    </SearchPageContainer>
  );
};

export default SearchPage;
