import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DriboStudent } from '../types/user';
import userService from '../services/user';

import Avatar from './avatar';
import { IonIcon } from '@ionic/react';

import { event_gray, clock_gray } from '../assets/icons/index';

const PracticeSummaryCardContainer = styled.div`
  .avatar-row {
    margin-right: 1.7rem;
  }

  .avatar-col {
    margin-bottom: 1.6rem;
  }

  .avatar-row > .avatar {
    width: 4.8rem;
    height: 4.8rem;
    margin-right: 0;
  }

  .avatar-col > .avatar {
    width: 6.4rem;
    height: 6.4rem;
    margin-right: 0;
  }

  .main-text-large {
    margin-bottom: 0.8rem;
  }

  span {
    margin-left: 0.6rem;
    padding-top: 0.05rem;
  }

  .date {
    margin-right: 0.9rem;
  }

  ion-icon {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
interface PracticeSummaryCardProps {
  user: DriboStudent;
  date: string;
  start: string;
  end: string;
  mode: 'row' | 'col';
}
const PracticeSummaryCard: React.FC<PracticeSummaryCardProps> = ({
  user,
  date,
  start,
  end,
  mode
}) => {
  const { t } = useTranslation('calendar');
  return (
    <PracticeSummaryCardContainer
      className={`${mode === 'col' ? 'flex-vertical justify-center align-items-center ' : ''} ${
        mode === 'row' ? 'flex' : ''
      }`}
    >
      <Avatar
        user={user}
        className={`${mode === 'row' ? 'avatar-row' : ''} ${mode === 'col' ? 'avatar-col' : ''}`}
      />
      <div className="flex-vertical justify-center">
        <div className="main-text-large semibold">{userService.fullName(user)}</div>
        <div className="flex">
          <div className="flex">
            <IonIcon icon={event_gray} />
            <span className="main-text-small date">{date}</span>
          </div>
          <div className="flex">
            <IonIcon icon={clock_gray} />
            <span className="main-text-small">
              {start} {t('to')} {end}
            </span>
          </div>
        </div>
      </div>
    </PracticeSummaryCardContainer>
  );
};

export default PracticeSummaryCard;
