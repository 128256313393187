import { IonSearchbar } from '@ionic/react';
import styled from 'styled-components';
import React from 'react';
const SearchBarContainer = styled.div`
  .searchbar-search-icon {
    width: 1.8rem;
    left: 15px;
  }

  ion-searchbar {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding: 0;
    height: 4.8rem;
    --background: #f4f5fa;
  }

  .searchbar-input-container {
    height: 100%;
  }

  .searchbar-input {
    padding-inline-start: 4.2rem !important;
    font-size: 1.6rem;
  }
`;

interface SearchBarProps {
  disabled?: boolean;
  placeholder: string;
  onChange: (value: string) => void;
}
export const SearchBar: React.FC<SearchBarProps> = ({ disabled, placeholder, onChange }) => {
  return (
    <SearchBarContainer>
      <IonSearchbar
        disabled={disabled}
        placeholder={placeholder}
        onIonChange={(e) => onChange(e.detail.value || '')}
        mode="ios"
      />
    </SearchBarContainer>
  );
};
