import React from 'react';
import styled from 'styled-components';

const WarningMarkContainer = styled.div`
  .warning-mark {
    width: 1.6rem;
    height: 1.6rem;
    background-color: var(--ion-color-warning);
    color: var(--ion-color-white);
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: center;
    z-index: 1;
  }
`;

export const WarningMark: React.FC<{ className?: string }> = (props) => (
  <WarningMarkContainer>
    <div className={`circle warning-mark ${props.className ? props.className : ''}`}>!</div>
  </WarningMarkContainer>
);

export default WarningMark;
