import React, { useState, useEffect } from 'react';
import { IonSplitPane, IonRouterOutlet } from '@ionic/react';
import CalendarPage from './pages/calendar';
import LoginPage from './pages/login';
import SearchPage from './pages/search';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import StudentProfilePage from './pages/student-profile';
import PracticeDetailPage from './pages/practice-detail';
import NotAppearedFormPage from './pages/not-appeared-form';
import CancelPracticePage from './pages/cancel-practice';
import LockPracticePage from './pages/lock-practice';
import ActionsAfterNotAppearedPage from './pages/actions-after-not-appeared';
import Menu from './components/menu';
import { initStudentsList } from './services/school';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import 'react-toastify/dist/ReactToastify.css';
import './theme.css';
import { AuthListener } from './services/firebase-adapter';
import userService from './services/user';
import { LoadingScreen } from './components/loading-screen';
import { AuthProvider } from './contexts/authContext';
import { PrivateRoute } from './components/private-route';
import { DriboStudent, DriboTeacher } from './types/user';
import { useIntercom } from 'react-use-intercom';
import { ToastContainer } from 'react-toastify';
import { AssignPracticePage } from './pages/assign-practice';
import { useDribo } from './contexts/driboContext';

const App = () => {
  const [appReady, setAppReady] = useState(false);
  const [user, setUser] = useState<DriboTeacher | null>(null);
  const history = useHistory();
  const location = useLocation();
  const [students, setStudents] = useState<DriboStudent[]>([]);
  const { boot, shutdown } = useIntercom();
  const { selectedCar } = useDribo();

  const bootIntercom = (teacher: DriboTeacher) => {
    if (!teacher.supportEnabled) return;

    boot({
      name: teacher.name,
      email: teacher.name,
      userId: teacher.uid,
      customAttributes: {
        School: teacher.schoolUid
      },
      hideDefaultLauncher: true
    });
  };

  const shutdownIntercom = () => shutdown();

  useEffect(() => {
    AuthListener(async (fbUser) => {
      if (fbUser) {
        const teacher = await userService.initTeacher(fbUser.uid);
        setUser(teacher);
        bootIntercom(teacher);
      } else {
        setUser(null);
        shutdownIntercom();
        if (!location.pathname.includes('/login')) {
          history.push('/login');
        }
      }
      if (!appReady) {
        setAppReady(true);
      }
    });
  }, []);

  // User changed, re-filter the students
  useEffect(() => {
    if (user) {
      (async () => await getStudents())();
    }
  }, [user]);

  // Selected car changed, re-filter the students
  useEffect(() => {
    if (selectedCar) {
      (async () => await getStudents())();
    }
  }, [selectedCar]);

  const getStudents = async () => {
    const { isFromStorage, refreshStorage, students } = await initStudentsList();

    const filterStudents = (students: DriboStudent[]) => {
      return students.filter((student) => student.car && student.car === selectedCar);
    };
    // Set the value, we pulled the students from storage or API
    setStudents(filterStudents(students));

    // If we pulled the students from the storage, we fetch the API to refresh
    // the local copy
    if (isFromStorage && refreshStorage) {
      const newStudents = await refreshStorage();
      if (newStudents && newStudents.length) {
        setStudents(filterStudents(students));
      }
    }
  };

  if (!appReady) return <LoadingScreen />;
  return (
    <AuthProvider user={user}>
      <ToastContainer />
      <IonSplitPane contentId="main">
        <Menu loggedUser={user} />
        <IonRouterOutlet id="main">
          <Route path="/login">
            {(props) => {
              // TS inference failing here, we use any to pass the props, in LoginPage
              // we have typed props again
              return user ? <Redirect to="/calendar" /> : <LoginPage {...(props as any)} />;
            }}
          </Route>
          <Route path="/login/password-reset" exact>
            {(props) => {
              return user ? <Redirect to="/calendar" /> : <LoginPage {...(props as any)} />;
            }}
          </Route>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <PrivateRoute exact path="/calendar" render={(props) => <CalendarPage {...props} />} />
          <PrivateRoute
            exact
            path="/search"
            render={(props) => <SearchPage {...props} students={students} />}
          />
          <PrivateRoute
            exact
            path="/student-profile/:uid"
            render={(props) => <StudentProfilePage {...props} />}
          />
          <PrivateRoute
            exact
            path="/practice-detail/:pid"
            render={(props) => <PracticeDetailPage {...props} />}
          />
          <PrivateRoute
            exact
            path="/not-appeared-form/:pid"
            render={(props) => <NotAppearedFormPage {...props} />}
          />
          <PrivateRoute
            exact
            path="/actions-after-not-appeared"
            render={(props) => <ActionsAfterNotAppearedPage {...props} />}
          />
          <PrivateRoute
            exact
            path="/cancel-practice/:practiceId"
            render={(props) => <CancelPracticePage {...props} />}
          />
          <PrivateRoute
            exact
            path="/assign-practice/:practiceDate/:practiceTime"
            render={(props) => <AssignPracticePage students={students} {...props} />}
          />
          <PrivateRoute
            exact
            path="/lock-practice/:car/:year/:month/:day/:slot"
            render={(props) => <LockPracticePage {...props} />}
          />
        </IonRouterOutlet>
      </IonSplitPane>
    </AuthProvider>
  );
};

export default App;
