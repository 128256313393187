import { titlerize } from 'src/utils';
import { DriboStudent, DriboTeacher, PracticeUserTypes } from '../types/user';
import firebaseService, {
  createSession,
  callFunction,
  sendPasswordResetEmail
} from './firebase-adapter';
import storage from './storage';

const ERRORS: { [key: string]: string } = {
  'auth/invalid-email': 'error_invalidEmail',
  'auth/user-not-found': 'error_userNotFound',
  'auth/wrong-password': 'error_userNotFound'
};

export const logout = async () => {
  await storage.clear();
  return firebaseService.signOutFirebaseUser();
};

export const login = (email: string, pass: string) => {
  return firebaseService
    .authFirebaseUser(email, pass)
    .then((uid) => {
      return firebaseService
        .databaseGet<DriboTeacher>(`teachers/${uid}`)
        .then((u) => fetchTeacherProfile(u))
        .then((u) => createSession(u))
        .catch((e) => Promise.reject({ code: 'auth/user-not-found' }));
    })
    .catch((e) => Promise.reject(ERRORS[e.code as string] || e.code));
};
export const initTeacher = async (uid: string) => {
  const teacher = await firebaseService.databaseGet<DriboTeacher>(`teachers/${uid}`);
  const profile = await fetchTeacherProfile(teacher);
  await createSession(profile);
  return profile;
};
export const fullName = (user: DriboTeacher | DriboStudent) => {
  const fullName = ['name', 'lastName', 'lastName2']
    .map((e) => (user as any)[e])
    .filter((a) => !!a)
    .join(' ');

  return titlerize(fullName);
};

export const fetchStudentProfile = (userId: string) => {
  return callFunction<DriboStudent>('schoolData-getPracticeStudentByUid', {
    uid: userId
  });
};

export const fetchTeacherProfile = (user: DriboTeacher) => {
  return firebaseService.databaseGet<DriboTeacher>(`schools/${user.school}/teachers/${user.uid}`);
};

export const fetchExamHistoryArray = (uid: string, type: string) =>
  firebaseService
    .databaseGet<any>(`examHistory/${uid}/${type}`)
    .then((res) => (res ? Object.keys(res) : []));

export const fetchSchoolUserInfo = (uid: string, sid: string) =>
  firebaseService.databaseGet(`schoolUserInfo/${uid}-${sid}`);

export const storeSignatureAndGetURL = (uid: string, id: string, signature: string) =>
  firebaseService
    .storeBase64(uid, id, signature)
    .then((snap) => firebaseService.getDownloadURL(snap));

export const removeUnneededDocs = (practiceUserType: PracticeUserTypes, docs: any) => {
  let finalDocs = { ...docs };
  switch (practiceUserType) {
    case 'hasAttemptsExternal':
      if (Array.isArray(docs)) finalDocs = docs.filter((item) => item !== 'examApplication');
      else delete finalDocs.examApplication;
      break;
    case 'noPracticeExam':
      if (Array.isArray(docs)) {
        finalDocs = docs.filter(
          (item) =>
            item !== 'examApplication' && item !== 'traspaso' && item !== 'talon' && item !== 'cm'
        );
      } else {
        delete finalDocs.examApplication;
        delete finalDocs.traspaso;
        delete finalDocs.talon;
        delete finalDocs.cm;
      }
      break;
    default:
      if (Array.isArray(docs)) finalDocs = docs.filter((item) => item !== 'traspaso');
      else delete finalDocs.traspaso;
  }
  return finalDocs as any[];
};

export const recoverPassword = (email: string) => {
  return sendPasswordResetEmail(email);
};

export default {
  removeUnneededDocs,
  storeSignatureAndGetURL,
  fetchSchoolUserInfo,
  fetchExamHistoryArray,
  fetchTeacherProfile,
  fetchStudentProfile,
  fullName,
  initTeacher,
  login,
  logout
};
