import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { IonButton } from '@ionic/react';
import SignaturePad from 'signature_pad';
import { useTranslation } from 'react-i18next';

const SignaturePadContainer = styled.div`
  padding: 2.5rem 2.5rem 1.8rem 2.5rem;

  #signature-pad {
    border: 1px solid var(--ion-color-border);
    border-radius: 0.5rem;
  }

  ion-button {
    --padding-start: 0;
    --padding-end: 0;
    margin: 0;
  }

  .pad-title {
    margin-bottom: 0.8rem;
  }
`;

interface SignPadProps {
  title: string;
  value: string;
  className?: string;
  save: (signature: string | null) => void;
}
const SignPad: React.FC<SignPadProps> = (props) => {
  const { t } = useTranslation('signature-pad');
  const { title, value, className } = props;
  const [signPad, setSignPad] = useState<SignaturePad>();
  const signPadRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    setSignPad(newCanvas());
  }, []);

  useEffect(() => {
    if (signPad) {
      signPad.clear();
      if (value) signPad.fromDataURL(value, { ratio: 1 });
    }
  }, [value]);

  const newCanvas = () => {
    if (signPadRef.current) {
      const signaturePad = new SignaturePad(signPadRef.current, {
        backgroundColor: 'transparent',
        penColor: 'black'
      });
      return signaturePad;
    }
  };

  const clearPad = () => {
    signPad && signPad.clear();
  };

  const saveSignature = () => {
    if (signPad) {
      if (signPad.isEmpty()) props.save(null);
      else props.save(signPad.toDataURL());
    }
  };

  return (
    <SignaturePadContainer className={`height-100 ${className ? className : ''}`}>
      <div className="flex-vertical between height-100">
        <div className="flex between align-items-center pad-title">
          <span className="main-text-dark">{t(title)}</span>
          <IonButton
            className="main-text primary semibold"
            onClick={clearPad}
            fill="clear"
            size="small"
          >
            {t('remove')}
          </IonButton>
        </div>
        <canvas ref={signPadRef} width={338} height={338} id="signature-pad" />
        <IonButton className="main-button" onClick={saveSignature}>
          {t('save')}
        </IonButton>
      </div>
    </SignaturePadContainer>
  );
};

export default SignPad;
