import React, { useState } from 'react';
import { IonItem, IonLabel, IonInput, IonIcon, IonTextarea } from '@ionic/react';
//@ts-ignore
import { stylesheet } from 'stylesheet-decorator/react';

interface InputProps {
  className?: string;
  onChange: (value: string) => void;
  label: string;
  type?: string;
  value?: string;
  disabled?: boolean;
  icon?: string;
  onIconClick?: (event: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => void;
  autocomplete?: boolean;
  name?: string;
  isTextArea?: boolean;
}
const Input: React.FC<InputProps> = (props) => {
  const { className, icon, onIconClick, ...inputProps } = props;
  const [focused, setFocused] = useState(false);
  const classes = focused ? 'ion-focused' : '';

  return (
    <IonItem lines="none" className={`${classes} ${className ? className : ''}`}>
      <IonLabel position="floating">{props.label}</IonLabel>
      {props.isTextArea ? (
        <IonTextarea
          value={props.value}
          onIonBlur={() => setFocused(false)}
          onIonFocus={() => setFocused(true)}
          onIonChange={(e) => props.onChange(e.detail.value ? e.detail.value : '')}
        />
      ) : (
        <IonInput
          {...(inputProps as any)}
          onIonBlur={() => setFocused(false)}
          onIonFocus={() => setFocused(true)}
          type={(props.type as any) || 'text'}
          onIonChange={(e) => props.onChange(e.detail.value ? e.detail.value : '')}
        />
      )}
      {icon ? (
        <IonIcon
          className="input-icon"
          icon={icon}
          onClick={(event) => onIconClick && onIconClick(event)}
        />
      ) : (
        <></>
      )}
    </IonItem>
  );
};

export default stylesheet<React.FC<InputProps>>(
  `
  ion-item {
    margin: 1rem 0;
    --color: #8D8D8D;
    --border-color: #8D8D8D;
    --border-radius: 4px;
    --border-width: 0;
    --min-height: 4.8rem !important;
    --background-focused: var(--ion-color-primary-shade);
    --background-focused-opacity: 1;
    width: 100%;
    --background: var(--ion-color-input-bg);
    --background-active: var(--ion-color-primary-darker);
  }

  ion-item::part(native) {
    padding-left: 1.5rem;
  }

  ion-item ion-input {
    --color: var(--ion-color-dark-text) !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    margin-bottom: 0;
    font-size: 1.6rem !important;
  }

  ion-label {
    font-size: 1.5rem !important;
    --color: var(--ion-color-light-text) !important;
    margin-top: 0.7rem;
  }

  ion-item:not(.item-has-focus):not(.item-has-value) ion-label.label-floating {
    transform: translate3d(0, 1.5rem, 0) !important;
  }

  ion-item.item-has-focus ion-label.label-floating {
    color: var(--ion-color-primary);
  }

  .input-icon {
    position: absolute;
    top: 13px;
    right:13px;
    z-index: 99;
  }
`,
  Input
);
