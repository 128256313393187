import React from 'react';
import { Tabs } from 'src/components/tabs';
import styled from 'styled-components';
import { StudentProfileTabBar } from './student-profile-tab-bar';

interface StudentProfileTabsProps {
  personalInfoTab: () => React.ReactNode;
  documentationTab: () => React.ReactNode;
  practicesTab: () => React.ReactNode;
}
export const StudentProfileTabs: React.FC<StudentProfileTabsProps> = ({
  personalInfoTab,
  documentationTab,
  practicesTab
}) => {
  return (
    <StudentProfileTabsContainer>
      <Tabs
        renderTabBar={(tabBarProps) => <StudentProfileTabBar {...tabBarProps} />}
        tabs={[personalInfoTab, documentationTab, practicesTab]}
      ></Tabs>
    </StudentProfileTabsContainer>
  );
};

const StudentProfileTabsContainer = styled.div`
  height: 100%;
`;
